import React, { useEffect ,useState} from "react";
import { Navigate } from "react-router-dom";
import { useSelector , useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { setuserData } from "../../store/auth/userCredSlice";
let common_service_ref = {};
const Authmiddleware = (props) => {
  const dispatch = useDispatch();
  // const [isRun , setIsRun] = useState(false);
  const [cookies] = useCookies(['userAuth']);
  
 
  
  const authDetails = useSelector((state) => state.user);
  // const service_ref= authDetails.user.account_short_guids[0];
  common_service_ref = {
    //  common_service_ref:service_ref,
  };

  if (
    localStorage.getItem("isAuthenticated") === "true" 
    // && authDetails.user !== ("undefined " || "null")
  ) {
    return <Navigate to={{ pathname: "/dashboard" }} />;
  }

  return <React.Fragment>{props.children}</React.Fragment>;
};
// Exporting the object
export { common_service_ref };

export default Authmiddleware;

