import React,{useEffect, useState} from "react";
import ReactApexChart from "react-apexcharts";

const BarChart = ({ storeAssmntRolesData }) => {
const [assmntCompleted, setAssmntsCompleted] = useState([]);
const [assmntInitiated, setAssmntsInitiated] = useState([]);
const [assmntTotalCounts, setAssmntTotalCounts] = useState([]);
const [assmntRoles, setAssmntRoles] = useState([]);

useEffect(() => {
  // Extract data from storeAssmntRolesData and update state variables
  const first9Records = storeAssmntRolesData.slice(0, 10);
  if (Array.isArray(first9Records)) {
    const assmntCompletedData = first9Records.map(
      (item) => item.assessment_completed_count,
    );
    const assmntInitiatedData = first9Records.map(
      (item) => item.assessment_initiated_count,
    );
    const assmntTotalCountsData = first9Records.map(
      (item) => item.total_count,
    );
    const assmntRolesData = first9Records.map((item) => item._id);

    setAssmntsCompleted(assmntCompletedData);
    setAssmntsInitiated(assmntInitiatedData);
    setAssmntTotalCounts(assmntTotalCountsData);
    setAssmntRoles(assmntRolesData);
   
  }
}, [storeAssmntRolesData]); // Run the effect whenever storeAssmntRolesData changes
const series = [
  {
    name: "Assessments Initiated",
    data: assmntInitiated,
  },
  {
    name: "Assessments Completed",
    data: assmntCompleted,
  },
  {
    name: "Total Assessments",
    data: assmntTotalCounts,
  },
 
];
  const options = {
    chart: {
      type: "bar",
      height: 430,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: false,
      offsetX: -6,
      style: {
        fontSize: "12px",
        colors: ["#fff"],
      },
    },
    stroke: {
      show: true,
      width: 0,
      colors: ["#fff"],
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    colors: ["#B6E7FA", "#01ABED", "#28419E"],
    xaxis: {
      categories: assmntRoles,
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="bar"
      height="370"
      className="apex-charts"
    />
  );
};

export default BarChart;
