import React, { useState, useEffect } from "react";
//elements and methods
import { ep } from "../../config/dev";
import { api_get} from "../../services/service";
import {
  CardText,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap";

// Custom Components
import TIQcard from "../allCandidates/TIQCard";
import CodeSnippetModal from "./codeSnippetModal";
import BehaviouralDetailsModal from "./behaviouralDetailsModal";
import McqDetailsOffcanvas from "./McqDetailsOffcanvas";

// Libraries
import classnames from "classnames";
import moment from "moment/moment";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { Link } from "react-router-dom";
// import AlertTriangle from "feather-icons-react/build/IconComponents/AlertTriangle";

const AssessmentSessionsOffCanvas = ({
  is_Right,
  assmntGuid,
  toggleRightCanvas,
}) => {
  const [mcqDetails, setMcqDetails] = useState([]);
  const [codingDetails, setCodingDetails] = useState([]);
  const [behaviouralDetails, setBehaviouralDetails] = useState([]);
  const [isPreloader, setIsPreloader] = useState(false);
  const [ is_view_code_snippet_modal_active,
    setIs_view_code_snippet_modal_active,
  ] = useState(false);
  const [is_video_assmnt_details_active, setIs_video_assmnt_details_active] = useState();
  const [codeSnippet, setCodeSnippet] = useState("");
  const [videoDetailsSession, setVideoDetailsSession] = useState("");
  const [isMcqModalActive, setIsMcqModalActive] = useState(false);
  const [storemcqDetails, setStoreMcqDetails] = useState("");
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggle1 = (tab) => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab);
    }
  };
  const [activeTab1, setactiveTab1] = useState("5");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  // const [cd_customActiveTab, setcd_customActiveTab] = useState(0);
  // const toggleCDCustom = (tab) => {
  //   if (cd_customActiveTab !== tab) {
  //     setcd_customActiveTab(tab);
  //   }
  // };
  // const [bd_customActiveTab, setbd_customActiveTab] = useState(0);
  // const toggleBDCustom = (tab) => {
  //   if (bd_customActiveTab !== tab) {
  //     setbd_customActiveTab(tab);
  //   }
  // };

  useEffect(() => {
    if (isPreloader === true) {
      document.getElementById("preloader").style.display = "block";
    } else {
      document.getElementById("preloader").style.display = "none";
    }
  }, [isPreloader]);

  useEffect(() => {
    setIsPreloader(true);
    api_get(
      null,
      ep.assessment + "/v1/assessment/assessmentsession/" + assmntGuid.guid,
      (_assessmentsession_err, _assessmentsession_res) => {
        if (_assessmentsession_res) {
          const mcqIds = [];
          const codingIds = [];
          const videoIds = [];
  
          // Loop through assessment sessions and store ids based on type
          _assessmentsession_res.assessments.forEach((session) => {
            const { type, guid } = session; // Assuming there are 'type' and 'id' properties in each assessment session
            if (type === "mcq") {
              mcqIds.push(guid);
            } else if (type === "coding") {
              codingIds.push(guid);
            } else if (type === "behavioural") {
              videoIds.push(guid);
            }
  
            // Add more conditions for other types if needed
          });
          function fetchMcqDetails(mcqIds) {
            const codingPromises = mcqIds.map((coding_assmnt) => {
              return fetchAssessmentDetails(coding_assmnt);
            });
  
            return Promise.all(codingPromises);
          }
  
          function fetchVideoDetails(videoIds) {
            return fetchAssessmentDetails(videoIds);
          }
  
          function fetchCodingDetails(codingIds) {
            const codingPromises = codingIds.map((coding_assmnt) => {
              return fetchAssessmentDetails(coding_assmnt);
            });
  
            return Promise.all(codingPromises);
          }
          
          Promise.all([
            fetchMcqDetails(mcqIds),
            fetchVideoDetails(videoIds),
            fetchCodingDetails(codingIds),
          ]).then(([mcqDetails, videoDetails, codingDetails]) => {
            setIsPreloader(false);
  
            setMcqDetails(mcqDetails);
            setBehaviouralDetails(videoDetails);
            setCodingDetails(codingDetails);
          });
        }
      },
      );
    }, []);


  function on_click_show_mcq_assmnt(mcq_details) {
  setStoreMcqDetails(mcq_details);
  setIsMcqModalActive(!isMcqModalActive);
  }

  function fetchAssessmentDetails(guid) {
    return new Promise((resolve, reject) => {
      api_get(
        null,
        ep.assessment + "/v1/assessmentquestion/assessment/" + guid,
        (err, response) => {
          if (err) {
            reject(err);
          } else {
            if (response.hasOwnProperty("assessmentquestions")) {
              resolve(response.assessmentquestions);
            } else if (response.hasOwnProperty("assessmentquestion")) {
              resolve([response.assessmentquestion]); // Wrap it in an array to match the structure
            }
          }
        },
      );
    });
  }
  // const requestBody = {
  //   certificate: {
  //     assessment_session_guid: "",
  //   },
  // };



  const on_click_view_code_snippet = (code_snippet) => {
    setIs_view_code_snippet_modal_active(!is_view_code_snippet_modal_active);
    setCodeSnippet(code_snippet);
  };
  const on_click_show_video_assmnt = (behavioural_data) => {
    setIs_video_assmnt_details_active(!is_video_assmnt_details_active);
    setVideoDetailsSession(behavioural_data);
  };

  return (
    <React.Fragment>
      <div>
        <Offcanvas
          style={{ width: "725px", zoom: "80%" }}
          isOpen={is_Right}
          direction="end"
          toggle={toggleRightCanvas}
        >
          <OffcanvasHeader className="border-bottom" toggle={toggleRightCanvas}>
            <h5 className="text-primary">
              Assessment Details&nbsp;&nbsp;
              <span className="font-size-12 text-muted mt-2">
                ⓘ Present comprehensive details for the Selected Candidates
                Assessment.
              </span>
            </h5>
          </OffcanvasHeader>
          <OffcanvasBody className="p-0">
            {/* TIQ Card/////////////// */}

            {assmntGuid && assmntGuid.score && assmntGuid.score.tiq_score > 0 ? <TIQcard assmntCardData={assmntGuid} /> :''}

            <Col lg={12}>
              <Nav
                tabs
                className="nav-tabs-custom nav-justified-start ms-3"
                style={{ textTransform: "uppercase" }}
              >
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleCustom("1");
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="far fa-user"></i>
                    </span>
                    <span className="d-none d-sm-block">MCQ </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleCustom("2");
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="far fa-envelope"></i>
                    </span>
                    <span className="d-none d-sm-block">Coding </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "3",
                    })}
                    onClick={() => {
                      toggleCustom("3");
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="fas fa-cog"></i>
                    </span>
                    <span className="d-none d-sm-block">Behavioural </span>
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent
                activeTab={customActiveTab}
                className="p-3 text-muted"
              >
                <TabPane tabId="1">
                  <CardText className="mb-0">
                    {mcqDetails.map((innerArray, index) => (
                      <div key={index}>
                        {innerArray.map((item, innerIndex) => (
                          <div className="border border-2 py-2 px-3 mb-2">
                            <Row>
                              <Col lg={9}>
                                <div className="flex-grow-1 mb-1">
                                  <h5 className="font-size-14 mb-1">
                                    {item.question}
                                  </h5>
                                  <p className="text-muted">
                                    <p className="font-size-12">
                                      <em>Submitted Answer:</em>&nbsp;&nbsp;
                                      <span className="font-size-14">
                                        {item.submitted_answer}
                                      </span>
                                    </p>
                                  </p>
                                  <div>
                                    <Link
                                      to="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        on_click_show_mcq_assmnt(item);
                                      }}
                                      className="text-primary mb-2"
                                    >
                                      Read more{" "}
                                      <i className="mdi mdi-arrow-right" />
                                    </Link>
                                  </div>
                                </div>
                              </Col>
                              <Col lg={3} className="">
                                <div className="hstack d-block">
                                  <span
                                    className={`badge bg-${
                                      item.result === -1
                                        ? "warning"
                                        : item.result === 0
                                          ? "danger"
                                          : "success"
                                    }-subtle text-muted float-end m-1`}
                                  >
                                    {" "}
                                    {item.result === 0
                                      ? "Incorrect"
                                      : item.result === -1
                                        ? "Not Answered"
                                        : "correct"}
                                  </span>
                                  &nbsp;
                                  <span className="badge bg-primary-subtle text-primary float-end m-1">
                                    {item.expertise}
                                  </span>
                                  &nbsp;
                                </div>
                              </Col>
                            </Row>
                          </div>
                        ))}
                      </div>
                    ))}
                  </CardText>
                </TabPane>

                <TabPane tabId="2">
                  {/* TODO NS New Component Start */}

                  <Col sm="12">
                    <CardText className="mb-0">
                      {codingDetails.map((code, index) => (
                        <div key={index}>
                          {code.map((innerCode, innerIndex) => (
                            <div className="flex-grow-1 mb-3 ">
                              <div className="border border-2 px-2 py-2">
                                <div className="d-flex align-items-start">
                                  <div className="flex-grow-1 overflow-hidden">
                                    <h5 className="font-size-15 text-truncate">
                                      <a href="#" className="text-body">
                                        {innerCode.question}
                                      </a>
                                    </h5>
                                    <p className="font-size-13 text-muted mb-0">
                                      {moment(innerCode.createdAt).format(
                                        "DD MMM , YYYY",
                                      )}
                                    </p>
                                  </div>
                                  <div className="flex-shrink-0 ms-2">
                                    <div
                                      className=""
                                      style={{ width: "50px", height: "40px" }}
                                    >
                                      <CircularProgressbarWithChildren
                                        styles={{
                                          root: {}, // Adjust these values
                                          path: {
                                            stroke: "#5156be",
                                            strokeLinecap: "round",
                                            transition:
                                              "stroke-dashoffset 0.5s ease 0s",
                                            transform: "rotate(0.25turn)",
                                            transformOrigin: "center center",
                                            pathColor: "#74788d",
                                          },
                                          trail: {
                                            stroke: "#d6d6d6",
                                            strokeLinecap: "round",
                                            transform: "rotate(0.25turn)",
                                            transformOrigin: "center center",
                                          },
                                        }}
                                        value={(
                                          (innerCode.score /
                                            innerCode.max_score) *
                                          100
                                        ).toFixed(0)}
                                      >
                                        <div style={{ fontSize: 11 }}>
                                          <strong>
                                            {(
                                              (innerCode.score /
                                                innerCode.max_score) *
                                              100
                                            ).toFixed(0)}
                                            %
                                          </strong>
                                        </div>
                                      </CircularProgressbarWithChildren>
                                    </div>
                                  </div>
                                </div>
                                <div className="pt-1">
                                  <ul className="list-inline">
                                    {innerCode.skills.map((skill, index) => (
                                      <li className="list-inline-item me-3">
                                        <a
                                          href="javascript: void(0);"
                                          className="text-muted"
                                        >
                                          <i className="bx bx-purchase-tag-alt align-middle text-muted me-1" />{" "}
                                          {skill}
                                        </a>
                                      </li>
                                    ))}

                                    {/* <li className="list-inline-item me-3">
                                  <a href="javascript: void(0);" className="text-muted">
                                    <i className="bx bx-purchase-tag-alt align-middle text-muted me-1" />{" "}
                                    Skill 2
                                  </a>
                                </li> */}
                                  </ul>
                                  <p className="text-muted">
                                    {innerCode &&
                                      innerCode.code_result &&
                                      innerCode.code_result.summary}
                                  </p>
                                  <div>
                                    <a
                                      href="javascript: void(0);"
                                      onClick={() => {
                                        on_click_view_code_snippet(innerCode);
                                      }}
                                      className="text-primary"
                                    >
                                      Read more{" "}
                                      <i className="mdi mdi-arrow-right" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ))}
                    </CardText>
                  </Col>
                </TabPane>

                <TabPane tabId="3">
                  {behaviouralDetails.map((behavioural, index) => (
                    <div key={index}>
                      {/* <div className="flex-grow-1 mb-3 px-3"> */}
                      <div className="flex-grow-1 mb-3">
                        <div className="border border-2 px-2 py-2">
                          <div className="d-flex align-items-start">
                            <div className="flex-grow-1 overflow-hidden">
                              <h5 className="font-size-15 text-truncate">
                                <a href="#" className="text-body">
                                  {behavioural.question}
                                </a>
                              </h5>
                              <p className="font-size-13 text-muted mb-0">
                                {moment(behavioural.createdAt).format(
                                  "DD MMM , YYYY",
                                )}
                              </p>
                            </div>
                            <div className="flex-shrink-0 ms-2">
                              <div
                                className=""
                                style={{ width: "50px", height: "40px" }}
                              >
                                <CircularProgressbarWithChildren
                                  styles={{
                                    root: {}, // Adjust these values
                                    path: {
                                      stroke: "#5156be",
                                      strokeLinecap: "round",
                                      transition:
                                        "stroke-dashoffset 0.5s ease 0s",
                                      transform: "rotate(0.25turn)",
                                      transformOrigin: "center center",
                                      pathColor: "#74788d",
                                    },
                                    trail: {
                                      stroke: "#d6d6d6",
                                      strokeLinecap: "round",
                                      transform: "rotate(0.25turn)",
                                      transformOrigin: "center center",
                                    },
                                  }}
                                  value={(
                                    (behavioural.score /
                                      behavioural.max_score) *
                                    100
                                  ).toFixed(0)}
                                >
                                  <div style={{ fontSize: 11 }}>
                                    <strong>
                                      {(
                                        (behavioural.score /
                                          behavioural.max_score) *
                                        100
                                      ).toFixed(0)}
                                      %
                                    </strong>
                                  </div>
                                </CircularProgressbarWithChildren>
                              </div>
                            </div>
                          </div>
                          <div className="pt-1">
                            <ul className="list-inline">
                              {behavioural.skills.map((skill, index) => (
                                <li className="list-inline-item me-3">
                                  <a
                                    href="javascript: void(0);"
                                    className="text-muted"
                                  >
                                    <i className="bx bx-purchase-tag-alt align-middle text-muted me-1" />{" "}
                                    {skill}
                                  </a>
                                </li>
                              ))}

                            </ul>
                            <p className="text-muted">
                              {behavioural &&
                                behavioural.video_transcript_result &&
                                behavioural.video_transcript_result.summary}
                            </p>
                            <div>
                              <a
                                href="javascript: void(0);"
                                onClick={() => {
                                  on_click_show_video_assmnt(behavioural);
                                }}
                                className="text-primary"
                              >
                                Read more <i className="mdi mdi-arrow-right" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                 
                </TabPane>
               
              </TabContent>
            </Col>
          </OffcanvasBody>
        </Offcanvas>
        <CodeSnippetModal
          isModalActive={is_view_code_snippet_modal_active}
          onToggleModal={on_click_view_code_snippet}
          codeSnippet={codeSnippet}
        />
        <BehaviouralDetailsModal
          isModalActive={is_video_assmnt_details_active}
          onToggleModal={on_click_show_video_assmnt}
          videoData={videoDetailsSession}
        />

        <McqDetailsOffcanvas
          isCanvasActive={isMcqModalActive}
          onToggleCanvas={on_click_show_mcq_assmnt}
          mcqDetails={storemcqDetails}
        />
      </div>
    </React.Fragment>
  );
};

export default AssessmentSessionsOffCanvas;
