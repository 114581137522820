import React from "react";
// import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>{new Date().getFullYear()} © TalentIQ.</Col>
            <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">
                {/* <Link to="#" className="ms-1 text-decoration-underline">
                Privacy 
                </Link> */}
                <a
                  href="https://www.talentiq.app/privacy"
                  className="ms-1 text-decoration-underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy
                </a>
                {/* <Link to="#" className="ms-1 text-decoration-underline">
                  Terms of Services
                </Link> */}
                <a
                  href="https://www.talentiq.app/terms"
                  className="ms-1 text-decoration-underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Services
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
