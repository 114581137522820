import React, { useState, useEffect } from "react";
import { ep } from "../../config/dev";
import { api_get } from "../../services/service";
import {
  Row,
  Col,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  CardBody,
  Card,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  NavLink,
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import DonutChart from "../../common/charts/donutchart";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import classnames from "classnames";

//mcqIds, codingIds, videoIds
const CompaniesOffCanvas = ({
  is_Right,
  toggleRightCanvas,
  job_guid_array,
}) => {
  const [activeTab1, setactiveTab1] = useState("5");

  const toggle1 = (tab) => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab);
    }
  };
  const [percentages, setPercentages] = useState({});
  let percentagesData = {};
  let locations = [];
  let locationCountsVar = {};
  let allJobsArray = [];
  let skillset = [];
  let skillsCounts = {};
  let skillsPercentages = {};

  // let jobListingsArray = [];
  const [availableJobs, setAvailableJobs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const promises = job_guid_array.map((jobGuid) => {
          return new Promise((resolve, reject) => {
            api_get(
              null,
              ep.entity + "/v1/joblisting/" + jobGuid,
              (_company_err, _company_res) => {
                if (_company_err) {
                  console.error(
                    `Error fetching job listing for jobGuid ${jobGuid}:`,
                    _company_err,
                  );
                  reject(_company_err);
                } else {
                  resolve(_company_res.joblisting);
                }
              },
            );
          });
        });

        const jobListingsArray = await Promise.all(promises);
        setAvailableJobs(jobListingsArray);

        jobListingsArray.forEach((company) => {
          const location = company.location;
          locations.push(location.split(",")[0]);

          const locationCounts = {};
          locations.forEach((location) => {
            if (locationCounts[location]) {
              locationCounts[location]++;
            } else {
              locationCounts[location] = 1;
            }
          });
          locationCountsVar = locationCounts;
          const totalSum = Object.values(locationCountsVar).reduce(
            (sum, value) => sum + value,
            0,
          );
          for (const key in locationCountsVar) {
            if (locationCountsVar.hasOwnProperty(key)) {
              percentagesData[key] = (locationCountsVar[key] / totalSum) * 100;
              allJobsArray.push(percentagesData[key]);
            }
          }

          //for skills demanded
          const skills = company.skill_requirements;
          if (Array.isArray(skills) && skills.length > 0) {
            skillset.push(...skills);
          }
          skillset.forEach((skills) => {
            if (skillsCounts[skills]) {
              skillsCounts[skills]++;
            } else {
              skillsCounts[skills] = 1;
            }
          });
          const totalSums = Object.values(skillsCounts).reduce(
            (sum, value) => sum + value,
            0,
          );
          for (const key in skillsCounts) {
            if (skillsCounts.hasOwnProperty(key)) {
              skillsPercentages[key] = (skillsCounts[key] / totalSums) * 100;
            }
          }
        });
        //for job skills
        setPercentages(percentagesData);
      } catch (error) {
      }
    };

    fetchData();
  }, [job_guid_array]);

  return (
    <React.Fragment>
      <div>
        <Offcanvas
          style={{ width: "650px" }}
          isOpen={is_Right}
          direction="end"
          toggle={toggleRightCanvas}
        >
          <OffcanvasHeader toggle={toggleRightCanvas}></OffcanvasHeader>
          <OffcanvasBody>
            <Col lg={12}>
              {/* <Card> */}
              {/* <CardHeader>
                
                </CardHeader> */}
              {/* <CardBody> */}
              <Nav
                pills
                className="nav-justified nav-tabs-custom rounded card-header-tabs"
              >
                {/* nav-tabs-custom nav-justified */}
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab1 === "5",
                    })}
                    onClick={() => {
                      toggle1("5");
                    }}
                  >
                    Frequent Job Locations
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab1 === "6",
                    })}
                    onClick={() => {
                      toggle1("6");
                    }}
                  >
                    Frequent Skills Demanded
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={activeTab1} className="p-3 text-muted">
                <TabPane tabId="5">
                  <Row>
                    <Col sm="12">
                      {/* <Card>
              <CardBody> */}
                      {/* <h6 className=''>Frequent Job Locations</h6> */}

                      <div className="d-flex h-10">
                        <DonutChart percentages={percentages} />
                        <div className="m-3">
                          {" "}
                          <Card className="border border-info">
                            {/* <div className="card-header bg-transparent border-info">
                                    <h5 className="my-0 text-info"><i></i>Info Card</h5>
                                </div> */}
                            <CardBody>
                              <h5 className="card-title">card title</h5>
                              <p className="card-text">
                                Some quick example text to build on the card
                                title and make up the bulk of the card's
                                content.
                              </p>
                            </CardBody>
                          </Card>
                        </div>
                      </div>

                      {/* </CardBody>
            </Card> */}
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="6">
                  <Row>
                    <Col sm="12">
                      {/* <Card>
              <CardBody> */}
                      {/* <h6 className=''>Frequent Job Locations</h6> */}

                      <div className="d-flex h-10">
                        <DonutChart percentages={percentages} />
                        <div className="m-3">
                          {" "}
                          <Card className="border border-info">
                            {/* <div className="card-header bg-transparent border-info">
                                    <h5 className="my-0 text-info"><i></i>Info Card</h5>
                                </div> */}
                            <CardBody>
                              <h5 className="card-title">card title</h5>
                              <p className="card-text">
                                Some quick example text to build on the card
                                title and make up the bulk of the card's
                                content.
                              </p>
                            </CardBody>
                          </Card>
                        </div>
                      </div>

                      {/* </CardBody>
            </Card> */}
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
              {/* </CardBody> */}
              {/* </Card> */}
            </Col>
            <Row>
              <Col>
                {/* <Card>
                  <CardBody> */}
                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    <Table
                      id="tech-companies-1"
                      className="table table-striped table-bordered"
                      // table table-hover mb-0
                    >
                      <Thead>
                        <Tr>
                          <Th data-priority="1">Job Title</Th>
                          <Th data-priority="1">Company</Th>
                          <Th data-priority="3">Location</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {availableJobs.map((job, index) => (
                          <tr key={index}>
                            <td>{job.title}</td>
                            <td>{job.company}</td>
                            <td>{job.location}</td>
                          </tr>
                        ))}
                      </Tbody>
                    </Table>
                  </div>
                </div>
                {/* </CardBody>
                </Card> */}
              </Col>
            </Row>
          </OffcanvasBody>
        </Offcanvas>
      </div>
    </React.Fragment>
  );
};

export default CompaniesOffCanvas;
