import React, { useState, useEffect } from "react";
import {
  CardText,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Input,
  Button,
} from "reactstrap";
import { ToastContainer ,toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Select from "react-select";
import { useSelector } from "react-redux";
// import MyCandidatesOffCanvas from "../../components/mycandidates/MyCandidatesOffCanvas";
import { Link } from "react-router-dom";
import CustomTable from "../../common/CustomTable";
//Import Breadcrumb
import { ep } from "../../config/dev";
import { api_get } from "../../services/service";
import Breadcrumbs from "../../components/Common/Breadcrumb";
// import AddNewCandidateOffCanvas from "../../components/candidates/AddNewCandidateOffCanvas";
import AddNewCandidateOffCanvas from "../../components/candidates/AddNewCandidateOffCanvas";
import CandidatesOffCanvas from "../../components/candidates/CandidatesOffCanvas";
import classnames from "classnames";
import moment from "moment/moment";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { current } from "@reduxjs/toolkit";
const CandidatesAll = () => {
  const authDetails = useSelector((state) => state.user);

  const userSessios_guid = authDetails.user.guid;
  const [myCandidates, setMyCandidate] = useState([]);
  const [customActiveTab, setcustomActiveTab] = useState("1");

  const [isPreloader, setIsPreloader] = useState(false);
  const [isRightAllCandidatesCanvas, setIsRightAllCandidatesCanvas] = useState(false);
  const [isRightMyCandidatesCanvas, setIsRightMyCandidatesCanvas] = useState(false);
  const [mycandidateGuid, setMyCandidateGuid] = useState("");
  const [is_add_new_cand_modal_active, setIs_add_new_cand_modal_active] = useState(false);
  const [allCandidates, setAllCandidates] = useState([]);
  const [candidateGuid, setCandidateGuid] = useState("");
  const [searchedWord, setSearchedWord] = useState('');
    const [totalCounts , setTotalCounts] = useState();
    const [currentPage, setCurrentPage] = useState(0);
    const [currentPageCand, setCurrentPageCand] = useState(0);
    const [totalCountsCand , setTotalCountsCand] = useState();
  
  // const [candidateProfileDetails , setCandidatesProfileDetails] = useState({})
  let SearchedValue = "";

  const on_click_add_new_candidate = (e) => {
    setIs_add_new_cand_modal_active(!is_add_new_cand_modal_active);
  };

  const on_click_mycandidate_name = (candidate_guid) => {
    setMyCandidateGuid(candidate_guid);

    setIsRightMyCandidatesCanvas(!isRightMyCandidatesCanvas);
  };

  function handleSearchedVal(event) {
    SearchedValue = event.target.value;
  }
  const handleClick = (event) => {
    // event.preventDefault();
    setSearchedWord(SearchedValue);
    // 👇️ value of input field
  };

  //for my candidates columns
  const myCandidatescolumns = [
    {
      Header: "Name",
      accessor: "full_name",
      Cell: ({ row }) => (
        <Link
          to="#"
          // style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={() =>
            on_click_mycandidate_name(row.original.guid, row.original)
          }
        >
          {row.original.full_name}
        </Link>
      ),
    },
    {
      Header: "Role | Status | Score",
      accessor: "role",
      Cell: ({ row }) =>
        <Row>
          <Col lg={8}>
            <div>
              {(row.original.assessmentsessions &&
                row.original.assessmentsessions.length > 0 &&
                row.original.assessmentsessions[0].role) ||
                ""}
            </div>
            <div>
              {
                (row.original.assessmentsessions &&
                  row.original.assessmentsessions.length > 0 && (
                    <div
                      className={`bg-${row.original.assessmentsessions[0].status === "completed"
                          ? "success text-white"
                          : row.original.assessmentsessions[0].status === "initiated"
                            ? "primary text-white"
                            : "warning text-white"
                        } badge bg-secondary`}
                    >
                      {row.original.assessmentsessions[0].status}
                    </div>
                  )) ||
                "-"
              }
            </div>

          </Col>

          <Col lg={1}>
            {(row.original.assessmentsessions &&
              row.original.assessmentsessions.length > 0 &&  row.original.assessmentsessions[0].score && row.original.assessmentsessions[0].score.tiq_score>0 ? (
                <div style={{ width: "40px", height: "40px" }} >
                  {" "}
                  <CircularProgressbarWithChildren
                    styles={{
                      root: {}, // Adjust these values
                      path: {
                        stroke: "#5156be",
                        strokeLinecap: "round",
                        transition: "stroke-dashoffset 0.5s ease 0s",
                        transform: "rotate(0.25turn)",
                        transformOrigin: "center center",
                        pathColor: "#74788d",
                      },
                      trail: {
                        stroke: "#d6d6d6",
                        strokeLinecap: "round",
                        transform: "rotate(0.25turn)",
                        transformOrigin: "center center",
                      },
                    }}
                    value={row.original.assessmentsessions[0].score && row.original.assessmentsessions[0].score.tiq_score.toFixed(0)}
                  >
                    <div style={{ fontSize: 9 }}>
                      <strong>
                        {row.original.assessmentsessions[0].score && row.original.assessmentsessions[0].score.tiq_score.toFixed(0)}
                        %
                      </strong>
                    </div>
                  </CircularProgressbarWithChildren>
                </div>
              ): '')}
          </Col>
        </Row>


    },

    {
      Header: "Email | Contact",
      accessor: "email_id",
      Cell: ({ row }) => (
        <div className="vstack ">
          <div className="font-size-14 text-primary">
            {row.original.email_id}
          </div>
          <div className="font-size-13">{row.original.contact_no}</div>
        </div>
      ),
    },
    // {
    //   Header: "Phone No.",
    //   accessor: "contact_no",
    // },
    // {
    //   Header: "Experience",
    //   accessor: "overall_experience",
    // },
    {
      Header: "Created Date",
      accessor: "createdAt",
      Cell: ({ row }) => moment(row.original.createdAt).format("DD MMM , YYYY"),
    },

    // Add more columns as needed
  ];
  const allCandidatesColumns = [
    {
      Header: "Name",
      accessor: "full_name",
      Cell: ({ row }) => (
        <Link
          to="#"
          // style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={() =>
            on_click_all_candidate_name(row.original.guid, row.original)
          }
        >
          {row.original.full_name}
        </Link>
      ),
    },
    {
      Header: "Role | Status | Score",
      accessor: "role",
      Cell: ({ row }) =>
        <Row >
          <Col lg={8}>
            <div>
              {(row.original.assessmentsessions &&
                row.original.assessmentsessions.length > 0 &&
                row.original.assessmentsessions[0].role) ||
                ""}
            </div>
            <div>
              {
                (row.original.assessmentsessions &&
                  row.original.assessmentsessions.length > 0 && (
                    <div
                      className={`bg-${row.original.assessmentsessions[0].status === "completed"
                          ? "success text-white"
                          : row.original.assessmentsessions[0].status === "initiated"
                            ? "primary text-white"
                            : "warning text-white"
                        } badge bg-secondary`}
                    >
                      {row.original.assessmentsessions[0].status}
                    </div>
                  )) ||
                ""
              }
            </div>

          </Col>

          <Col lg={2}>
            {(row.original.assessmentsessions &&
              row.original.assessmentsessions.length > 0 && (
                <div style={{ width: "40px", height: "40px" }}>
                  {" "}
                  <CircularProgressbarWithChildren
                    styles={{
                      root: {}, // Adjust these values
                      path: {
                        stroke: "#5156be",
                        strokeLinecap: "round",
                        transition: "stroke-dashoffset 0.5s ease 0s",
                        transform: "rotate(0.25turn)",
                        transformOrigin: "center center",
                        pathColor: "#74788d",
                      },
                      trail: {
                        stroke: "#d6d6d6",
                        strokeLinecap: "round",
                        transform: "rotate(0.25turn)",
                        transformOrigin: "center center",
                      },
                    }}
                    value={row.original.assessmentsessions[0].score.tiq_score.toFixed(0)}
                  >
                    <div style={{ fontSize: 9 }}>
                      <strong>
                        {row.original.assessmentsessions[0].score.tiq_score.toFixed(0)}
                        %
                      </strong>
                    </div>
                  </CircularProgressbarWithChildren>
                </div>
              )) ||
              "-"}
          </Col>
        </Row>


    },

    
    {
      Header: "Email | Contact",
      accessor: "email_id",
      Cell: ({ row }) => (
        <div className="vstack ">
          <div className="font-size-14 text-primary">
            {row.original.email_id}
          </div>
          <div className="font-size-13">{row.original.contact_no}</div>
        </div>
      ),
    },
    // {
    //   Header: "Experience",
    //   accessor: "overall_experience",
    // },
    {
      Header: "Created Date",
      accessor: "createdAt",
      Cell: ({ row }) => moment(row.original.createdAt).format("DD MMM , YYYY"),
    },

    // Add more columns as needed
  ];

  //   const toggle = (tab) => {

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  const handleSearchOnEnter = (event) => {
    if (event.key === "Enter") {
      // Perform your search logic here
      handleClick();
    }
  };
  const on_click_all_candidate_name = (candidate_guid, details) => {
    setCandidateGuid(candidate_guid);
    setIsRightAllCandidatesCanvas(!isRightAllCandidatesCanvas);
  };
  const handleMyCandPageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const handleCandPageChange = (newPage) => {
    setCurrentPageCand(newPage);
  };


  // rendering all candidates ///////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    setIsPreloader(true);
    api_get(
      null,
      `${ep.assessment}/v1/candidate/?searchQuery=${searchedWord}&limit=10`,
      (_candidate_err, _candidate_res) => {
        setTotalCountsCand(_candidate_res.total_data);
        if (_candidate_res && _candidate_res.candidates.length > 0) {
          let bind_candidates = _candidate_res.candidates;

          let bind_candidates_sorted = bind_candidates.map((ele) => {
            return {
              ...ele,
              assessmentsessions: ele.assessmentsessions
                .filter((session) => session.status === "completed")
                .sort(
                  (a, b) => new Date(b.completedAt) - new Date(a.completedAt),
                ),
            };
          });
          setAllCandidates(bind_candidates_sorted);
          setIsPreloader(false);
        }
        else {
          toast.warning("No Records Found !" , { position:toast.POSITION.TOP_RIGHT,autoClose:2000})
          setIsPreloader(false);
        }
      },
    );
  }, [searchedWord]);
  useEffect(() => {
    setIsPreloader(true);
    api_get(
      null,
      `${ep.assessment}/v1/candidate/?limit=10&offset=${currentPageCand}`,
      (_candidate_err, _candidate_res) => {
        
       
        if (_candidate_res && _candidate_res.candidates.length > 0) {
          let bind_candidates = _candidate_res.candidates;

          let bind_candidates_sorted = bind_candidates.map((ele) => {
            return {
              ...ele,
              assessmentsessions: ele.assessmentsessions
                .filter((session) => session.status === "completed")
                .sort(
                  (a, b) => new Date(b.completedAt) - new Date(a.completedAt),
                ),
            };
          });
          setAllCandidates(bind_candidates_sorted);
          setIsPreloader(false);
        }
        else {
          toast.warning("No Records Found !" , { position:toast.POSITION.TOP_RIGHT,autoClose:2000})
          setIsPreloader(false);
        }
      },
    );
  }, [currentPageCand])
  


  // rendering my candidates /////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    // setIsPreloader(true);
    api_get(
      null,
      // ep.assessment +"/v1/candidate?owner_guid=" +(userSessios_guid || localStorage.getItem("user_guid")) + "/?searchQuery="+ {searchedWord},
      `${ep.assessment}/v1/candidate?owner_guid=${userSessios_guid || localStorage.getItem("user_guid")
      }&searchQuery=${searchedWord}&limit=10`,
      (_candidate_err, _candidate_res) => {
        if (_candidate_res) {
          setTotalCounts(_candidate_res.total_data)
          // setIsPreloader(false);
          setMyCandidate(_candidate_res.candidates);
        }
        else if(_candidate_err){
          //  setIsPreloader(false);
        }
      },
    );
  }, [searchedWord, userSessios_guid]);
  useEffect(() => {
    api_get(
      null,
      // ep.assessment +"/v1/candidate?owner_guid=" +(userSessios_guid || localStorage.getItem("user_guid")) + "/?searchQuery="+ {searchedWord},
      `${ep.assessment}/v1/candidate?owner_guid=${userSessios_guid || localStorage.getItem("user_guid")
      }&limit=10&offset=${currentPage}`,
      (_candidate_err, _candidate_res) => {
        if (_candidate_res) {
          // setIsPreloader(false);
          setMyCandidate(_candidate_res.candidates);
        }
        else if(_candidate_err){
          //  setIsPreloader(false);
        }
      },
    );
  }, [currentPage])
  

  useEffect(() => {
    if (isPreloader === true) {
      document.getElementById("preloader").style.display = "block";
    } else {
      document.getElementById("preloader").style.display = "none";
    }
  }, [isPreloader]);
  return (
    <React.Fragment>
      <ToastContainer/>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Candidates" />

          {/* Render Breadcrumbs */}

          <Row>
            <div className="d-flex">
              <Col lg={4} md={6}>
                <div className="mb-2 me-2">
                  <Input
                    onChange={handleSearchedVal}
                    onKeyDown={handleSearchOnEnter}
                    className="form-control"
                    type="text"
                    id="searchedWord"
                    // value={searchedWord || " "}
                    placeholder="Global filter..."
                  />
                </div>
              </Col>

              <Col lg={1} md={6}>
                <Button onClick={handleClick} className="ms-2 bg-primary">
                  <i className="fa fa-search me-2"> </i>Search
                </Button>
              </Col>
              <Col lg={7}>
                {/* <div className="d-flex flex-row-reverse mb-3"> */}
                <button
                  className="btn btn-primary float-end"
                  onClick={on_click_add_new_candidate}
                >
                  <i className="fas fa-user-plus me-2"></i>Add Candidates
                </button>
                {/* </div> */}
              </Col>
            </div>

            <Col lg={12}>
              {/* <Card>
                <CardBody> */}
              {/* <Nav tabs className="nav-tabs-custom nav-justified"> */}
              <Nav
                tabs
                className="mt-2 nav-tabs-custom nav-justified-start nav nav-tab"
                
              >
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "1", 
                    })}
                    onClick={() => {
                      toggleCustom("1");
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="fas fa-home"></i>
                    </span>
                    <span className="d-none d-sm-block">My Candidates</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "2", 
                    })}
                    onClick={() => {
                      toggleCustom("2");
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="far fa-user"></i>
                    </span>
                    <span className="d-none d-sm-block">All Candidates</span>
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent
                activeTab={customActiveTab}
                className=" text-muted"
              >
                <TabPane tabId="1">
                  <Row>
                    <Col sm="12">
                      <CardText className="mb-0">
                        <CustomTable
                        currentPage={currentPage}
                          onPageChange={handleMyCandPageChange}
                          totalCount={totalCounts}
                          data={myCandidates}
                          columns={myCandidatescolumns}
                        />
                      </CardText>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col sm="12">
                      <CardText className="mb-0">
                        
                        <CustomTable
                         currentPage={currentPageCand}
                         onPageChange={handleCandPageChange}
                         totalCount={totalCountsCand}
                      
                          data={allCandidates}
                          columns={allCandidatesColumns}
                        />
                      </CardText>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>

            </Col>
          </Row>
        </Container>
        {isRightMyCandidatesCanvas && (
          <CandidatesOffCanvas
            is_Right={isRightMyCandidatesCanvas}
            toggleRightCanvas={on_click_mycandidate_name}
            candidate_guid={mycandidateGuid}
          // candidateProfileDetails={candidateProfileDetails}
          />
        )}
        {isRightAllCandidatesCanvas && (
          <CandidatesOffCanvas
            is_Right={isRightAllCandidatesCanvas}
            candidate_guid={candidateGuid}
            toggleRightCanvas={on_click_all_candidate_name}
          // candidateProfileDetails={candidateProfileDetails}
          />
        )}

        <AddNewCandidateOffCanvas
          isModalActive={is_add_new_cand_modal_active}
          onToggleModal={on_click_add_new_candidate}
        />
      </div>
    </React.Fragment>
  );
};

export default CandidatesAll;
