import React, { useState, useEffect } from "react";
//methods and elements
import { api_get } from "../../services/service";
import { ep } from "../../config/dev";
import { Row, Col, CardBody, Input, Button } from "reactstrap";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
//libraries
import Breadcrumbs from "../../components/Common/Breadcrumb";
// import TablePagination from "../../common/TablePagination";
import { Link } from "react-router-dom";
import AssessmentSessionsOffCanvas from "../../components/assessmentsessions/AssessmentSessionsOffCanvas";
import CustomTable from "../../common/CustomTable";
import CandidatesOffCanvas from "../../components/candidates/CandidatesOffCanvas";
import moment from "moment/moment";
import { ToastContainer ,toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import TableLoader from "../../components/Common/TableLoader";
function AssessmentSessions() {
  // const [state, setState] = useState("");
  const [assessments, setAssessments] = useState([]);
  const [isPreloader, setIsPreloader] = useState(false);
  const [searchedWord, setSearchedWord] = useState("");
  const [assmntData, setAssmntData] = useState("");
  const [isAssessmentRightCanvas, setIsAssessmentRightCanvas] = useState(false);
  const [isRightCandidatesCanvas, setIsRightCandidatesCanvas] = useState(false);
  const [candidateProfileDetails, setCandidatesProfileDetails] = useState({});
  const [candidateGuid, setCandidateGuid] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCounts , setTotalCounts] = useState();

  let SearchedValue = "";
  let defaultAssmntData = [];
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const columns = [
    {
      Header: "Name",
      accessor: "full_name",
      Cell: ({ row }) => (
        <Link
          to="#"
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={() =>
            on_click_candidte_name(row.original.candidate_guid, row.original)
          }
        >
          {row.original.candidates.length > 0
            ? row.original.candidates[0].full_name
            : "-"}
        </Link>
      ),
    },
    {
      Header: "Role",
      accessor: "role",
      Cell: ({ row }) => (
        <Link
          to="#"
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={() => on_click_assmnt_role(row.original)}
        >
          {row.original.role}
        </Link>
      ),
    },
    {
      Header: "Primary Skills",
      accessor: "primary_skills",
      Cell: ({ row }) =>
        row.original.primary_skills.length > 0 ? (
          // <ul style={{ padding: '1rem' }}>
          <>
            {" "}
            {row.original.primary_skills.map((skill, index) => (
              <span key={index} className="bg-success badge me-1 bg-primary">
                {skill.title}
              </span>
            ))}
          </>
        ) : (
          // </ul>
          "-"
        ),
    },

    {
      Header: "Score",
      accessor: "tiq_score",
      Cell: ({ row }) =>
        row.original.score && row.original.status === "completed" ? (
          <div style={{ width: "40px", height: "40px" }}>
          {" "}
          <CircularProgressbarWithChildren
            styles={{
              root: {}, // Adjust these values
              path: {
                stroke: "#5156be",
                strokeLinecap: "round",
                transition: "stroke-dashoffset 0.5s ease 0s",
                transform: "rotate(0.25turn)",
                transformOrigin: "center center",
                pathColor: "#74788d",
              },
              trail: {
                stroke: "#d6d6d6",
                strokeLinecap: "round",
                transform: "rotate(0.25turn)",
                transformOrigin: "center center",
              },
            }}
            value={ row.original.score.tiq_score.toFixed(2)}
          >
            <div style={{ fontSize: 9 }}>
              <strong>
                {
                  (row.original.score.tiq_score.toFixed(0))} %
              </strong>
            </div>
          </CircularProgressbarWithChildren>
        </div>
        ) : (
          "-"
        ),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => (
        <span
          className={`bg-success badge ${row.original.status === "completed"
              ? "bg-success"
              : row.original.status === "initiated"
                ? "bg-primary"
                : "bg-warning"
            }`}
        >
          {row.original.status}
        </span>
      ),
    },
    {
      Header: "Start Date",
      accessor: "createdAt",
      Cell: ({ row }) => (
        <span>{moment(row.original.createdAt).format("DD MMM,YYYY")}</span>
      ),
    },
    {
      Header: "End Date",
      accessor: "completedAt",
      Cell: ({ row }) => (
        <span>{row.original.status ==='completed' ? (moment(row.original.updatedAt).format("DD MMM,YYYY")) : '-'}</span>
      ),
    },
  ];
  useEffect(() => {
    api_get(
      null,
      `${ep.assessment}/v1/assessmentsession/?limit=10&offset=${currentPage}`,
      (_assessments_err, _assessments_res) => {
        if (_assessments_res) {
          setAssessments(_assessments_res.assessmentsessions);

          setIsPreloader(false);
        }
        else{
          // alert("No Records Found!");
          toast.warning("No Records Found !", {
            position: toast.POSITION.TOP_RIGHT,autoClose:2000
          });
          setIsPreloader(false);

        }
      },
    );
  }, [currentPage])
  

  useEffect(() => {
    setIsPreloader(true);
    api_get(
      null,
      `${ep.assessment}/v1/assessmentsession/?searchQuery=${searchedWord}&limit=10`,
      (_assessments_err, _assessments_res) => {
        if (_assessments_res) {
          setAssessments(_assessments_res.assessmentsessions);
          setTotalCounts(_assessments_res.total_data);
          setIsPreloader(false);
        }
        else{
          // alert("No Records Found!");
          toast.warning("No Records Found !", {
            position: toast.POSITION.TOP_RIGHT,autoClose:2000
          });
          setIsPreloader(false);

        }
      },
    );
  }, [searchedWord]);

  // const filteredAssessments = useMemo(() => {
  //   if (!state) {
  //     return assessments; // Return original data if no filter is applied
  //   }

  //   const lowercasedQuery = state.toLowerCase();
  //   return assessments.filter((rowData) => {
  //     return (
  //       rowData.candidates.some((candidate) =>
  //         candidate.full_name.toLowerCase().includes(lowercasedQuery),
  //       ) ||
  //       rowData.role.toLowerCase().includes(lowercasedQuery) ||
  //       rowData.primary_skills.some((skill) =>
  //         skill.title.toLowerCase().includes(lowercasedQuery),
  //       ) ||
  //       (rowData.secondary_skills &&
  //         rowData.secondary_skills.length > 0 &&
  //         rowData.secondary_skills[0].title
  //           .toLowerCase()
  //           .includes(lowercasedQuery)) ||
  //       rowData.status.toLowerCase().includes(lowercasedQuery) ||
  //       dateyyyymmdd_tohtml(rowData.createdAt)
  //         .toLowerCase()
  //         .includes(lowercasedQuery) ||
  //       (rowData.status === "completed" &&
  //         dateyyyymmdd_tohtml(rowData.completedAt)
  //           .toLowerCase()
  //           .includes(lowercasedQuery))
  //     );
  //   });

  // }, [assessments, state]);

  useEffect(() => {
    if (isPreloader === true) {
      document.getElementById("preloader").style.display = "block";
    } else {
      document.getElementById("preloader").style.display = "none";
    }
  }, [isPreloader]);
  const handleSearchOnEnter = (event) => {
    if (event.key === "Enter") {
      // Perform your search logic here
      handleClick();
    }
  };

  function handleSearchedVal(event) {
    if(event.target.value === '' || null || undefined){
      
      setSearchedWord('')
    }else{
      SearchedValue = event.target.value;

    }
  }
  const handleClick = (event) => {
    setSearchedWord(SearchedValue);
    // 👇️ value of input field
  };
  const on_click_assmnt_role = (assmntData) => {
    setAssmntData(assmntData);
    setIsAssessmentRightCanvas(!isAssessmentRightCanvas);
  };
  const on_click_candidte_name = (candidate_guid, assmntDetails) => {
    // setCandidate_assmnt_sess_details({});
    setCandidatesProfileDetails(assmntDetails);
    setCandidateGuid(candidate_guid);
    setIsRightCandidatesCanvas(!isRightCandidatesCanvas);
  };

  //meta title
  // document.title = "Responsive Table | Minia - React Admin & Dashboard Template";

  return (
    <React.Fragment>
      
      <ToastContainer/>
      <div className="page-content">
        <div className="container-fluid">
          <div className="hstack gap-3 mb-2 mt-0">

            <Breadcrumbs title="Assessments" />
          </div>

          <Row>
            <div className="d-flex">
              <Col lg={4} md={6}>
                <div className="mb-2 me-2">
                  <Input
                    onChange={handleSearchedVal}
                    onKeyDown={handleSearchOnEnter}
                    className="form-control"
                    type="text"
                    id="searchedWord"
                    placeholder="Global filter..."
                  />
                </div>
              </Col>

              <Col lg={1} md={6}>
                <Button onClick={handleClick} className="ms-2 bg-primary">
                  <i className="fa fa-search me-2"> </i>Search
                </Button>
              </Col>
            </div>
            <Col>
              {/* <Card> */}

              <CardBody>
                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                   {assessments.length >0 ? (<CustomTable
                   totalCount={totalCounts}
                   currentPage={currentPage}
                    data={assessments}
                    onPageChange={handlePageChange}
                     columns={columns} />) : <TableLoader/>}
                    {/* {
                      filteredAssessments.length > 0 ? (
                        <CustomTable data={filteredAssessments} columns={columns} />
                      ) : (<div className="hstack">
                         <Skeleton variant="wave" animation="pulse" className="mb-2 me-2" width={300} height={15} />
                        <Skeleton variant="wave" animation="pulse" className="mb-2 me-2" width={300} height={15} />
                        <Skeleton variant="wave" animation="pulse" className="mb-2 me-2" width={300} height={15} />
                        <Skeleton variant="wave" animation="pulse" className="mb-2 me-2" width={300} height={15} />
                        <Skeleton variant="wave" animation="pulse" className="mb-2 me-2" width={300} height={15} />
                      </div>
                      
                       
                      )
                      } */}

                  </div>
                </div>
              </CardBody>
              {/* </Card> */}
            </Col>
          </Row>
          {isRightCandidatesCanvas && (
            <CandidatesOffCanvas
              is_Right={isRightCandidatesCanvas}
              candidate_guid={candidateGuid}
              toggleRightCanvas={on_click_candidte_name}
              candidateProfileDetails={candidateProfileDetails}
            />
          )}
        </div>
        {isAssessmentRightCanvas && (
          <AssessmentSessionsOffCanvas
            is_Right={isAssessmentRightCanvas}
            toggleRightCanvas={on_click_assmnt_role}
            assmntGuid={assmntData}
          />
        )}
      </div>
    </React.Fragment>
  );
}

export default AssessmentSessions;
