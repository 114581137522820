import PropTypes from "prop-types";
import React, { useState } from "react";
import { setuserData } from "../../store/auth/userCredSlice";
import { useDispatch } from "react-redux";
import ToastComponent from "../../components/Common/ToastComponent";
import logo from "./../../assets/images/talentiq-logo.svg"
import {
  Row,
  Col,
  Container,
  Form,
  Input,
  // FormFeedback,
  Label,
} from "reactstrap";

// import { Navigate, Link } from "react-router-dom"
import { Link, Navigate } from "react-router-dom";

import withRouter from "../../components/Common/withRouter";
import { ep } from "../../config/dev";
import { api_post } from "../../services/service";
// Formik validation
// import * as Yup from "yup";
// import { useFormik } from "formik";
import CarouselPage from "../AuthenticationInner/CarouselPage";

const Login = (props) => {
  const serializeDate = (date) => date.toISOString();

  // const [passwordShow, setPasswordShow] = useState(false);
  const [isloggedIn, setIsLoggedIn] = useState(false);

  const dispatch = useDispatch();
  let SESSION_EXPIRY_MINUTES = 60;

 
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
   
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoggedIn(false);
    api_post(
      null,
      ep.identity + "/v1/user/authenticate/cred",
      formData,
      (_auth_err, _auth_res) => {
        if (_auth_res) {
         
          localStorage.setItem("isAuthenticated", true);
          localStorage.setItem("user_guid", _auth_res.user.guid);
          localStorage.setItem(
            "loggedInUserName",
            _auth_res.user.fname +" "+ _auth_res.user.lname,
          );
          localStorage.setItem("userRole", _auth_res.user.role);
          localStorage.setItem("account_short_guid", _auth_res.user.account_short_guids[0]);

          const userDataFromResponse = {
            user: _auth_res.user,
            start_date: serializeDate(new Date()),
            end_date: serializeDate(
              new Date(
                new Date().setMinutes(
                  new Date().getMinutes() + SESSION_EXPIRY_MINUTES,
                ),
              ),
            ),
            session_id: _auth_res.session_id,
            service_ref: _auth_res.user.account_short_guids[0],
            broker: _auth_res.broker,
          };
          sessionStorage.setItem(
            "loginTime",
            userDataFromResponse.start_date,
          );
          dispatch(setuserData(userDataFromResponse));
        } else {
          localStorage.setItem("isAuthenticated", false);

          setIsLoggedIn(true);
        }
      },
    );
    // Perform any actions on form submission, e.g., validation or API call
  };
  document.title = "Login | Admin - TalentIQ";

  return (
    <React.Fragment>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
        
            <Col lg={4} md={5} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
            <div className=" text-center">
      <img className="w-50" src={logo} alt="" />
       </div>
                  <div className="d-flex flex-column h-100">
                    {/* <div className="mb-4 mb-md-5 text-center">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img src={logo} alt="" height="28" /> <span className="logo-txt">Minia</span>
                      </Link>
                    </div> */}
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">Welcome Back !</h5>
                        <p className="text-muted mt-2">
                          Sign in to continue to Talent IQ.
                        </p>
                      </div>
                      <Form
                        className="custom-form mt-4 pt-2"
                        onSubmit={handleSubmit}
                      >
                        {/* {error ? <Alert color="danger">{error}</Alert> : null} */}
                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                           type="email"
                           id="email"
                           name="email"
                           value={formData.email}
                           onChange={handleChange}
                           placeholder="Enter your Email"
                          />
                         
                        </div>

                        <div className="mb-3">
                          <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                              <Label className="form-label">Password</Label>
                            </div>
                            <div className="flex-shrink-0">
                              <div className="">
                                <Link
                                  to="/page-recoverpw"
                                  className="text-muted"
                                >
                                  Forgot password?
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="input-group auth-pass-inputgroup">
                            <Input
                              type="password"
                              id="password"
                              name="password"
                              value={formData.password}
                              onChange={handleChange}
                              placeholder="Enter your Password"
                            />
                            {/* <button
                              onClick={() => setPasswordShow(!passwordShow)}
                              className="btn btn-light shadow-none ms-0"
                              type="button"
                              id="password-addon"
                            >
                              <i className="mdi mdi-eye-outline"></i>
                            </button>
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null} */}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <div className="col">
                            <div className="form-check">
                              {/* <input
                                className="form-check-input"
                                type="checkbox"
                                id="remember-check"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="remember-check"
                              >
                                Remember me
                              </label> */}
                            </div>

                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                              >
                                {/* {isloggedIn && ( */}
                                  {/* <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> */}
                                {/* )} */}
                                Log In
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="text-start"> 
                          <span className="font-size-12">Click Login to agree to our <Link to="https://www.talentiq.app/terms"  target="_blank">Terms of Services</Link> and acknowledge that Talent IQ's <Link to="https://www.talentiq.app/privacy"  target="_blank">Privacy Policy</Link> applies to you.</span>
                          </div>
                             
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage />
          </Row>
        </Container>
        {isloggedIn && (
          <ToastComponent
            message="Incorrect Username or Password , Try again with correct password"
            autoClose={false}
            type="danger"
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
