import React from 'react'
import {
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Row,
    Col,
  } from "reactstrap";
const DashboardHelper = ({ isCanvasActive,
    onToggleCanvas,
   }) => {
  return (
    <div> 
         <Offcanvas
    style={{ width: "400px", zoom: "80%" }}
    isOpen={isCanvasActive}
    direction="end"
    toggle={onToggleCanvas}
  >
    <OffcanvasHeader className="border-bottom" toggle={onToggleCanvas}>
      <h5 className="text-primary">
        Dashboard &nbsp;&nbsp;
        <span className="font-size-12 text-muted mt-2">
          ⓘ A visual appearance of the App
        </span>
      </h5>
    </OffcanvasHeader>
    <OffcanvasBody>
        </OffcanvasBody>
         /
         </Offcanvas>
    </div>
  )
}

export default DashboardHelper