import React, { useMemo, useState, useEffect } from "react";
import { api_get } from "../../services/service";
import { ep } from "../../config/dev";
import { Row, Col, CardBody, Input, Button } from "reactstrap";
import Select from "react-select";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import CompaniesOffCanvas from "../../components/companies/CompaniesOffCanvas";
import CustomTable from "../../common/CustomTable";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import moment from "moment/moment";
function Companies() {
  // let storeJobListings = [];
  const [company, setCompany] = useState([]);
  const [isPreloader, setIsPreloader] = useState(false);
  const [state, setState] = useState("");
  const [isRight, setIsRight] = useState(false);
  const [jobGuidArray, setJobGuidArray] = useState([]);
  const [selectedGroup, setselectedGroup] = useState(null);
  const [selectedOptions, setselectedOptions] = useState(null);
  const [searchedWord, setSearchedWord] = useState("");
  let SearchedValue = "";
  const rolesOptions = [
    { label: "java Developer", value: "java Developer" },
    { label: "python Developer", value: "python Developer" },
    { label: "Salesforce Developer", value: "Salesforce Developer" },
  ];
  const locationOptions = [
    { label: "Delhi", value: "Delhi" },
    { label: "Pune", value: "Pune" },
    { label: "Noida", value: "Noida" },
  ];

  const columns = [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ row }) => (
        <Link
          to="#"
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={() => on_click_company_name(row.original.joblistings)}
        >
          {row.original.name}
        </Link>
      ),
    },
    {
      Header: "Jobs",
      accessor: "jobs",
      Cell: ({ row }) =>
        row.original.joblistings && row.original.joblistings.length > 0
          ? row.original.joblistings.map((jobListing, index) => (
              <div key={index}>
                {jobListing.title}
                {index < row.original.joblistings.length - 1 && " | "}
              </div>
            ))
          : // Render something when joblistings array is empty
            "No job listings available",
    },
    {
      Header: "Industry",
      accessor: "industry",
    },
    {
      Header: "No. Of Employees",
      accessor: "no_of_employees",
    },
    {
      Header: "Headquarter",
      accessor: "headquarters",
    },
    {
      Header: "Created Date",
      accessor: "createdAt",
      Cell: ({ row }) => moment(row.original.createdAt).format("DD MMM, YYYY"),
    },
    {
      Header: "Source",
      accessor: "source",
    },

    // Add more columns as needed
  ];
  const on_click_company_name = (joblistings) => {
    if (!isRight) {
      const updatedJobGuidArray = joblistings.map((job) => job.guid);
      setJobGuidArray(updatedJobGuidArray);
    }
    setIsRight(!isRight);
  };

  useEffect(() => {
    setIsPreloader(true);
    api_get(
      null,
      `${ep.entity}/v1/company/?searchQuery=${searchedWord}`,
      (_company_err, _company_res) => {
        if (_company_res) {
          setCompany(_company_res.companies);
          setIsPreloader(false);
        }
      },
    );
  }, [searchedWord]);

  useEffect(() => {
    if (isPreloader === true) {
      document.getElementById("preloader").style.display = "block";
    } else {
      document.getElementById("preloader").style.display = "none";
    }
  }, [isPreloader]);
  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup);
  }

  function handleSelectOptions(selectedOptions) {
    setselectedOptions(selectedOptions);
  }

  function handleSearchedVal(event) {
    SearchedValue = event.target.value;
  }
  const handleClick = (event) => {
    event.preventDefault();
    setSearchedWord(SearchedValue);
    // 👇️ value of input field
  };

  //meta title
  // document.title = "Responsive Table | Minia - React Admin & Dashboard Template";
  return (
    <React.Fragment>
      {/* {ispreloader && */}

      {!isPreloader && (
        <div className="page-content">
          <div className="container-fluid">
            <div className="hstack gap-3 mb-2 mt-0">
              {/* <Input  className="form-control me-auto" type="text" style={{width:'30%' ,marginLeft:'688px'}} placeholder="Search records here..." /> */}
              <Breadcrumbs title="Companies" />
            </div>

            <Row>
              <div className="d-flex">
                <Col lg={4} md={6}>
                  <div className="mb-2 me-2">
                    <Input
                      onChange={handleSearchedVal}
                      className="form-control"
                      type="text"
                      id="searchedWord"
                      // value={searchedWord || " "}
                      placeholder="Global filter..."
                    />
                  </div>
                </Col>
                <Col lg={3} md={6}>
                  <div className="mb-2 me-2">
                    {/* <label htmlFor="choices-single-default" className="form-label font-size-13 text-muted">Default</label> */}
                    <Select
                      placeholder="Select Locations"
                      value={selectedGroup}
                      onChange={() => {
                        handleSelectGroup();
                      }}
                      options={locationOptions}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                </Col>

                <Col lg={4} md={6}>
                  <div className="mb-2">
                    {/* <label htmlFor="choices-single-groups" className="form-label font-size-13 text-muted">Option
                groups</label> */}
                    <Select
                      placeholder="Select Roles"
                      value={selectedOptions}
                      onChange={() => {
                        handleSelectOptions();
                      }}
                      options={rolesOptions}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                </Col>
                <Col lg={1} md={6}>
                  <Button onClick={handleClick} className="ms-2">
                    <i className="fa fa-search me-2"> </i>Search
                  </Button>
                </Col>
              </div>
              <Col>
                {/* <Card> */}

                <CardBody>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <CustomTable data={company} columns={columns} />
                    </div>
                  </div>
                </CardBody>
                {/* </Card> */}
              </Col>
            </Row>
            <CompaniesOffCanvas
              is_Right={isRight}
              toggleRightCanvas={on_click_company_name}
              job_guid_array={jobGuidArray}
            />
            {/* <div className="d-flex flex-row-reverse" style={{marginLeft:'80%'}}><TablePagination/></div> */}
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default Companies;
