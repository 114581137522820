import { createSlice } from "@reduxjs/toolkit";

const userCredSlice = createSlice({
  name: "user",
  initialState: {
    // Your initial state here
    user: {},
    start_date: "",
    end_date: "",
    session_id: "",
    service_ref: "",
    broker: "",
  },
  reducers: {
    setuserData: (state, action) => {
      // state.user = action.payload;
      const { user, start_date, end_date, session_id, service_ref, broker } =
        action.payload;

      // Update the state with data from the login response
      state.user = user;
      state.service_ref = service_ref;
      state.session_id = session_id;
      state.start_date = start_date;
      state.end_date = end_date;
      state.broker = broker;
    },
  },
});

export const { setuserData } = userCredSlice.actions;
export const getuserData = (state) => state.user;

export default userCredSlice.reducer;
