import PropTypes from "prop-types";
import React, { useEffect, useRef, useCallback, useState } from "react";
// import InvestedOverview from "../../common/InvestedOverview";
import { Navigate } from "react-router-dom";
//Import Icons
import FeatherIcon from "feather-icons-react";
//Import Scrollbar
import SimpleBar from "simplebar-react";
// MetisMenu
import MetisMenu from "metismenujs";
import { Link, useLocation } from "react-router-dom";
import withRouter from "../Common/withRouter";
//i18n
import { withTranslation } from "react-i18next";
import { api_get } from "../../services/service";
import { n_formatter } from "../../helpers/utils";
import { Progress } from "reactstrap";
const SidebarContent = (props) => {
  const [totalCandidates , setTotalCandidates] = useState('');
  useEffect(() => {

     api_get(
       null,
       `https://i8e43d5anj.execute-api.ap-south-1.amazonaws.com/latest/v1/dashbord`,
       (dashboard_err, dashboard_res) => {
         document.getElementById("preloader").style.display = "block";
 
         if (dashboard_res) {
           setTotalCandidates(dashboard_res.dashboard_counts.candidates.total_candidates);
           document.getElementById("preloader").style.display = "none";
         }
       },
     );
   }, [])
   
  
  const ref = useRef();
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const path = useLocation();
  const activeMenu = useCallback(() => {
    const pathName = path.pathname;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
    activeMenu();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul
            className="metismenu list-unstyled"
            id="side-menu"
            style={{ lineHeight: "0" }}
          >
            {/* <li className="menu-title">{props.t("Menu")} </li> */}
            <li>
              <Link to="/dashboard" className="">
                <FeatherIcon icon="home" /> <span>{props.t("Dashboard")}</span>
              </Link>
            </li>
            <li>
              <Link to="/candidates" className="">
                <FeatherIcon icon="tablet" />{" "}
                <span>{props.t("Candidates")}</span>
              </Link>
            </li>
           
            <li>
              <Link to="/jobs" className="">
                <FeatherIcon icon="file" /> <span>{props.t("Jobs")}</span>
              </Link>
            </li>
          
            <li>
              <Link to="/questioncorpuses" className="">
                <FeatherIcon icon="tag" />{" "}
                <span>{props.t("Question Master")}</span>
              </Link>
            </li>
            <li>
              <Link to="/assessmentsessions" className="">
                <FeatherIcon icon="tablet" />{" "}
                <span>{props.t("Assessments")}</span>
              </Link>
            </li>
          </ul>
        
        </div>
      </SimpleBar>
      <div
        className="card px-2 mt-2 me-2 bg-light"
        style={{ position: "absolute", bottom: "0px" }}
      >
        <div className="card-body border border-0 bg-primary text-light rounded">
          <div className="row">
            <div className="col-12">
              <span className="text-nowrap mb-1 d-block font-size-14 text-light">
                Usage Status
              </span>
              <div className="text-nowrap">
                <p className="mb-1 font-size-10">
                  <span style={{ height: 25 }}>
                    <i className="mdi mdi-speedometer-slow font-size-15"></i>{" "}
                  </span>
                  &nbsp; {n_formatter(totalCandidates) } tokens consumed out of 1k
                </p>
                {/* <div className=" mt-1" style={{ height: 5 }}> */}
                  {/* <div
                    className="progress-bar progress-bar-striped bg-white"
                    role="progressbar"
                    style={{ width: "100%" }}
                    aria-valuenow={855/1000 *100}
                    aria-valuemin={0}
                    aria-valuemax={1000}
                  >{800}</div> */}
                   <div>
                    <Progress color="secondary1" className="progress-sm" value={(totalCandidates/1000)*100}>
                    </Progress>
                  </div>
                {/* </div> */}
              </div>
            </div>
          </div>
          <p className="mt-1 font-size-10 mb-2">
            {(totalCandidates/1000 *100).toFixed(0)}% of your account tokens are utilised. Please upgrade to increase
            limit.
          </p>
          <a href="https://www.talentiq.app/contact" className="btn btn-light btn-sm" target="_blank"  rel="noopener noreferrer">
            Contact Us <i className="mdi mdi-arrow-right ms-1"></i>
          </a>
          <p className="mt-2 text-nowrap mb-1">
            <a href="https://www.talentiq.app/terms" className="text-light font-size-10" target="_blank"  rel="noopener noreferrer">
              Terms
            </a>
            &nbsp;&nbsp;
            <a href="https://www.talentiq.app/privacy" className="text-light font-size-10"  target="_blank"  rel="noopener noreferrer">
              Privacy Policy
            </a>
            &nbsp;
          </p>
          <p className="mt-0 text-light font-size-10">
            © 2024 Techila Global Services Ltd. v0.1.0
          </p>
        </div>
      </div>
      {/* Metering End */}
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
// import PropTypes from "prop-types";
// import React, { useEffect, useRef, useCallback, useState } from "react";
// // import InvestedOverview from "../../common/InvestedOverview";
// import { Navigate } from "react-router-dom";
// //Import Icons
// import FeatherIcon from "feather-icons-react";
// //Import Scrollbar
// import SimpleBar from "simplebar-react";
// // MetisMenu
// import MetisMenu from "metismenujs";
// import { Link, useLocation } from "react-router-dom";
// import withRouter from "../Common/withRouter";
// //i18n
// import { withTranslation } from "react-i18next";
// import { api_get } from "../../services/service";
// import { n_formatter } from "../../helpers/utils";
// import { Progress } from "reactstrap";
// const SidebarContent = (props) => {
//   const [totalCandidates , setTotalCandidates] = useState('');
//   useEffect(() => {

//      api_get(
//        null,
//        `https://i8e43d5anj.execute-api.ap-south-1.amazonaws.com/latest/v1/dashbord`,
//        (dashboard_err, dashboard_res) => {
//          document.getElementById("preloader").style.display = "block";
 
//          if (dashboard_res) {
//            setTotalCandidates(dashboard_res.dashboard_counts.candidates.total_candidates);
//            document.getElementById("preloader").style.display = "none";
//          }
//        },
//      );
//    }, [])
   
  
//   const ref = useRef();
//   const activateParentDropdown = useCallback((item) => {
//     item.classList.add("active");
//     const parent = item.parentElement;
//     const parent2El = parent.childNodes[1];

//     if (parent2El && parent2El.id !== "side-menu") {
//       parent2El.classList.add("mm-show");
//     }

//     if (parent) {
//       parent.classList.add("mm-active");
//       const parent2 = parent.parentElement;

//       if (parent2) {
//         parent2.classList.add("mm-show"); // ul tag

//         const parent3 = parent2.parentElement; // li tag

//         if (parent3) {
//           parent3.classList.add("mm-active"); // li
//           parent3.childNodes[0].classList.add("mm-active"); //a
//           const parent4 = parent3.parentElement; // ul
//           if (parent4) {
//             parent4.classList.add("mm-show"); // ul
//             const parent5 = parent4.parentElement;
//             if (parent5) {
//               parent5.classList.add("mm-show"); // li
//               parent5.childNodes[0].classList.add("mm-active"); // a tag
//             }
//           }
//         }
//       }
//       scrollElement(item);
//       return false;
//     }
//     scrollElement(item);
//     return false;
//   }, []);

//   const removeActivation = (items) => {
//     for (var i = 0; i < items.length; ++i) {
//       var item = items[i];
//       const parent = items[i].parentElement;

//       if (item && item.classList.contains("active")) {
//         item.classList.remove("active");
//       }
//       if (parent) {
//         const parent2El =
//           parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
//             ? parent.childNodes[1]
//             : null;
//         if (parent2El && parent2El.id !== "side-menu") {
//           parent2El.classList.remove("mm-show");
//         }

//         parent.classList.remove("mm-active");
//         const parent2 = parent.parentElement;

//         if (parent2) {
//           parent2.classList.remove("mm-show");

//           const parent3 = parent2.parentElement;
//           if (parent3) {
//             parent3.classList.remove("mm-active"); // li
//             parent3.childNodes[0].classList.remove("mm-active");

//             const parent4 = parent3.parentElement; // ul
//             if (parent4) {
//               parent4.classList.remove("mm-show"); // ul
//               const parent5 = parent4.parentElement;
//               if (parent5) {
//                 parent5.classList.remove("mm-show"); // li
//                 parent5.childNodes[0].classList.remove("mm-active"); // a tag
//               }
//             }
//           }
//         }
//       }
//     }
//   };

//   const path = useLocation();
//   const activeMenu = useCallback(() => {
//     const pathName = path.pathname;
//     let matchingMenuItem = null;
//     const ul = document.getElementById("side-menu");
//     const items = ul.getElementsByTagName("a");
//     removeActivation(items);

//     for (let i = 0; i < items.length; ++i) {
//       if (pathName === items[i].pathname) {
//         matchingMenuItem = items[i];
//         break;
//       }
//     }
//     if (matchingMenuItem) {
//       activateParentDropdown(matchingMenuItem);
//     }
//   }, [path.pathname, activateParentDropdown]);

//   useEffect(() => {
//     ref.current.recalculate();
//   }, []);

//   useEffect(() => {
//     new MetisMenu("#side-menu");
//     activeMenu();
//   }, []);

//   useEffect(() => {
//     window.scrollTo({ top: 0, behavior: "smooth" });
//     activeMenu();
//   }, [activeMenu]);

//   function scrollElement(item) {
//     if (item) {
//       const currentPosition = item.offsetTop;
//       if (currentPosition > window.innerHeight) {
//         ref.current.getScrollElement().scrollTop = currentPosition - 300;
//       }
//     }
//   }

//   return (
//     <React.Fragment>
//       <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
//         <div id="sidebar-menu">
//           <ul
//             className="metismenu list-unstyled"
//             id="side-menu"
//             style={{ lineHeight: "0" }}
//           >
//             {/* <li className="menu-title">{props.t("Menu")} </li> */}
//             <li>
//               <Link to="/dashboard" className="">
//                 <FeatherIcon icon="home" /> <span>{props.t("Dashboard")}</span>
//               </Link>
//             </li>
//             <li>
//               <Link to="/candidates" className="">
//                 <FeatherIcon icon="tablet" />{" "}
//                 <span>{props.t("Candidates")}</span>
//               </Link>
//             </li>
           
//             <li>
//               <Link to="/jobs" className="">
//                 <FeatherIcon icon="file" /> <span>{props.t("Jobs")}</span>
//               </Link>
//             </li>
          
//             <li>
//               <Link to="/questioncorpuses" className="">
//                 <FeatherIcon icon="tag" />{" "}
//                 <span>{props.t("Question Master")}</span>
//               </Link>
//             </li>
//             <li>
//               <Link to="/assessmentsessions" className="">
//                 <FeatherIcon icon="tablet" />{" "}
//                 <span>{props.t("Assessments")}</span>
//               </Link>
//             </li>
//           </ul>
        
//         </div>
//       </SimpleBar>
//       <div
//         className="card px-2 mt-2 me-2 bg-light"
//         style={{ position: "absolute", bottom: "0px" }}
//       >
//         <div className="card-body border border-0 bg-primary text-light rounded">
//           <div className="row">
//             <div className="col-6">
//               <span className="text-nowrap mb-1 d-block font-size-14 text-light">
//                 Usage Status
//               </span>
//               <div className="text-nowrap">
//                 <p className="mb-1 font-size-10">
//                   <span style={{ height: 25 }}>
//                     <i className="mdi mdi-speedometer-slow font-size-15"></i>{" "}
//                   </span>
//                   &nbsp; {n_formatter(totalCandidates) } tokens consumed out of 1k
//                 </p>
//                 {/* <div className=" mt-1" style={{ height: 5 }}> */}
//                   {/* <div
//                     className="progress-bar progress-bar-striped bg-white"
//                     role="progressbar"
//                     style={{ width: "100%" }}
//                     aria-valuenow={855/1000 *100}
//                     aria-valuemin={0}
//                     aria-valuemax={1000}
//                   >{800}</div> */}
//                    <div>
//                     <Progress color="secondary1" className="progress-sm" value={25}>
//                     </Progress>
//                   </div>
//                 {/* </div> */}
//               </div>
//             </div>
//           </div>
//           <p className="mt-1 font-size-10 mb-2">
//             {(totalCandidates/1000 *100).toFixed(0)}% of your account tokens are utilised. Please upgrade to increase
//             limit.
//           </p>
//           <a href="https://www.talentiq.app/contact" className="btn btn-light btn-sm" target="_blank"  rel="noopener noreferrer">
//             Buy Tokens <i className="mdi mdi-arrow-right ms-1"></i>
//           </a>
//           <p className="mt-2 text-nowrap mb-1">
//             <a href="https://www.talentiq.app/terms" className="text-light font-size-10" target="_blank"  rel="noopener noreferrer">
//               Terms
//             </a>
//             &nbsp;&nbsp;
//             <a href="https://www.talentiq.app/privacy" className="text-light font-size-10"  target="_blank"  rel="noopener noreferrer">
//               Privacy Policy
//             </a>
//             &nbsp;
//           </p>
//           <p className="mt-0 text-light font-size-10">
//             © 2024 Techila Global Services Ltd. v0.1.0
//           </p>
//         </div>
//       </div>
//       {/* Metering End */}
//     </React.Fragment>
//   );
// };

// SidebarContent.propTypes = {
//   location: PropTypes.object,
//   t: PropTypes.any,
// };

// export default withRouter(withTranslation()(SidebarContent));
