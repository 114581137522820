// import { common_service_ref } from "../routes/middleware/Authmiddleware";
import React, { useEffect } from 'react'
import { useSelector } from "react-redux";
import { getuserData,setuserData } from "../store/auth/userCredSlice";

export const ep = {
  //  identity: "http://localhost:3004",
  //  entity: "http://localhost:3001",
  //  assessment: "http://localhost:3003",
  identity: "https://qoyj2nxyal.execute-api.ap-south-1.amazonaws.com/latest",
  entity: "https://l08nloaza1.execute-api.ap-south-1.amazonaws.com/latest",
  assessment: "https://i8e43d5anj.execute-api.ap-south-1.amazonaws.com/latest",
  resume_parsing: "https://files.talentiq.app",
  document_pages:
    "https://dfhteux1g7.execute-api.ap-south-1.amazonaws.com/latest",
  assmnt_sess_role: "https://i8e43d5anj.execute-api.ap-south-1.amazonaws.com/",
};

export const common ={
  service_ref: localStorage.getItem("account_short_guid"),
  app_version: "0.0.1",
  guid_format: "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx",
  short_guid_format: "xxxxxxxx",
  log_enabled: true,
  // Use data from the Redux store

}




export const unhandled_error = {
  server_error: {
    code: "TIQ-UH-001",
    message:
      "There appears to be an error. Please reload your page, or contact customer service if the issue persists.",
  },
  traffic_error: {
    code: "TIQ-TF-001",
    message:
      "We're presently experiencing excessive traffic. Please try again later or contact customer support if the problem persists.",
  },
  insufficient_tokens_error: {
    code: "TIQ-INSFTK-001",
    message:
      "You do not have enough tokens to complete this action. Upgrade or contact customer service for more assistance..",
  },
  insufficient_privileges_error: {
    code: "TIQ-INSFPRV-001",
    message:
      "You do not have enough privileges to complete this action. Please contact your account administrator or reachout to customer service for more assistance..",
  },
};

export const plan = {
  "22300a8b-8e61-4ec0-a2e7-d153c914dbe4": { title: "TRIAL" },
  "a7a137f9-c755-434e-89f7-476fd7cd52d0": { title: "STARTER" },
  "340067f1-168d-4c01-9364-693912a340f4": { title: "PROFESSIONAL" },
};

// export const ph_config = {
//  key: "phc_EpJznfTdrISZIrhO0tSrY0hDwu8lJzTozmDbox7njHI",
//  url: "https://app.posthog.com",
// };

// function account_prop(prop_name) {
//  let subdomain = window.location.origin.split(".")[0].split("//")[1];
//  let acc_props = {};

//  acc_props["tiqdemo"] = { common: { service_ref: "02d45aaa" } };

//  try {
//   return acc_props[subdomain][prop_name.split(".")[0]][prop_name.split(".")[1]];
//  } catch (_prop_err) {
//   return null;
//  }
// }
// function account_prop(prop_name) {
//   let subdomain = window.location.origin.split(".")[0].split("//")[1];
//   let acc_props = {};

//   acc_props["tiqdemo"] = { common: { service_ref: "02d45aaa" } };
//   acc_props["account1-admin"] = { common: { service_ref: "b8ilcgos" } };
//   acc_props["companybench-admin"] = { common: { service_ref: "yvy7a5sb" } };
//   acc_props["techila-admin"] = { common: { service_ref: "t85mstrc" } };

//   try {
//     return acc_props[subdomain][prop_name.split(".")[0]][
//       prop_name.split(".")[1]
//     ];
//   } catch (_prop_err) {
//     return null;
//   }
// }
