

// import PropTypes from "prop-types";
// import React,{useEffect} from "react";

// import { Routes, Route } from "react-router-dom";
// // import { connect } from "react-redux";

// // Import Routes all
// import { userRoutes, authRoutes } from "./routes/allRoutes";

// // Import all middleware
// import Authmiddleware from "./routes/middleware/Authmiddleware";

// // layouts Format
// import VerticalLayout from "./components/VerticalLayout/";
// // import HorizontalLayout from "./components/HorizontalLayout/";
// import NonAuthLayout from "./components/NonAuthLayout";

// // Import scss
// import "./assets/scss/theme.scss";
// import "./assets/scss/preloader.scss";



// const App = (props) => {

//   function getLayout() {
//     let layoutCls = VerticalLayout;

//     return layoutCls;
//   }

//   const Layout = getLayout();
//   return (
//     <React.Fragment>
//       {/* <Router> */}
//       <Routes>
//         {authRoutes.map((route, idx) => (
//           <Route
//             path={route.path}
//             element={
//               <NonAuthLayout>   
//               {route.component}
//             </NonAuthLayout>
//               // </NonAuthLayout>
//             }
//             key={idx}
//             exact={true}
//           />
//         ))}

//         {userRoutes.map((route, idx) => (
//           <Route
//             path={route.path}
//             element={
             
//                  <Authmiddleware>
//                 <Layout>{route.component}</Layout>
//               </Authmiddleware>
//               }           
//             key={idx}
//             exact={true}
//           />
//         ))}
//       </Routes>
//     </React.Fragment>
//   );
// };



// export default App;
// // connect(mapStateToProps, null)(App)




import PropTypes from "prop-types";
import React,{useEffect} from "react";

import { Routes, Route } from "react-router-dom";
// import { connect } from "react-redux";

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
// import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";
import "./assets/scss/preloader.scss";



const App = (props) => {
  function getLayout() {
    let layoutCls = VerticalLayout;

    return layoutCls;
  }

  const Layout = getLayout();
  return (
    <React.Fragment>
      {/* <Router> */}
      <Routes>
        {authRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware>{route.component}</Authmiddleware>
              // </NonAuthLayout>
            }
            key={idx}
            exact={true}
          />
        ))}

        {userRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <NonAuthLayout>
                
                <Layout>{route.component}</Layout>
              </NonAuthLayout>
            }
            key={idx}
            exact={true}
          />
        ))}
      </Routes>
    </React.Fragment>
  );
};



export default App;
// connect(mapStateToProps, null)(App)
