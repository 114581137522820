
import React, { useEffect, useMemo, useState } from "react";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useFilters,
  useSortBy,
  useRowSelect,
} from "react-table";
// import Select from "react-select";

import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
// import { IoMdArrowDown, IoMdArrowUp, BiSearch } from "react-icons/io";
import { FiMoreHorizontal } from "react-icons/fi";
import { PiMicrosoftExcelLogoLight } from "react-icons/pi";
import { VscFilePdf } from "react-icons/vsc";
// import { FaCoffee } from "react-icons/fa";
import { FaFilter } from "react-icons/fa";
// import { FiMoreHorizontal } from "react-icons/fi";
import {
  Row,
  Col,
  Input,
  // Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
// import "./CustomTable.css"; // Import your custom CSS
import "./css/CustomTable.css";
// import "./"
import { Link } from "react-router-dom";

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;
  return (
    <input
      className="mt-2 form-control"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`filter records...`}
    />
  );
}

const CheckBoxs = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <input type="checkbox" ref={resolvedRef} {...rest} />
    </>
  );
});
CheckBoxs.displayName = "CheckBoxs"; // Add the display name here

const IndeterminateCheckbox2 = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  },
);
IndeterminateCheckbox2.displayName = "IndeterminateCheckbox2"; // Add the display name here

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input
          className="form-check-input"
          type="checkbox"
          ref={resolvedRef}
          {...rest}
        />
      </>
    );
  },
);
IndeterminateCheckbox.displayName = "IndeterminateCheckbox"; // Add the display name here

const CustomTable = ({
  columns,
  data,
  tableDatas,
  props,
  headerData,
  direction,
  totalCount,     //this
  onPageChange,     //this
currentPage,   //this
    ...args
}) => {
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    [],
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    //pageOptions,
    //gotoPage,
    //pageCount,
 
    // setPageSize,
    selectedFlatRows,
    allColumns,
    getToggleHideAllColumnsProps,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    // (hooks) => {
    //   hooks.visibleColumns.push((columns) => [
    //     {
    //       id: "selection",
    //       Header: ({ getToggleAllRowsSelectedProps }) => (
    //         <div>
    //           <IndeterminateCheckbox2 {...getToggleAllRowsSelectedProps()} />
    //         </div>
    //       ),
    //       Cell: ({ row }) => (
    //         <div>
    //           <IndeterminateCheckbox2 {...row.getToggleRowSelectedProps()} />
    //         </div>
    //       ),
    //     },
    //     ...columns,
    //   ]);
    // },
  );
  // const rolesOptions = [
  //   { label: "java Developer", value: "java Developer" },
  //   { label: "python Developer", value: "python Developer" },
  //   { label: "Salesforce Developer", value: "Salesforce Developer" },
  // ];
  // const locationOptions = [
  //   { label: "Delhi", value: "Delhi" },
  //   { label: "Pune", value: "Pune" },
  //   { label: "Noida", value: "Noida" },
  // ];

  // const [dropdownOpen, setDropdownOpen] = useState(false);
  const { pageIndex, pageSize, globalFilter } = state;
  // const [copyData, setCopyData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  // const [selectedGroup, setselectedGroup] = useState(null);
  // const [selectedOptions, setselectedOptions] = useState(null);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  useEffect(() => {
    setTableData(data);
  }, []);
  const itemsPerPage = 10; // Adjust as needed
  const startIndex = currentPage;
  const endIndex = startIndex + itemsPerPage;
  const [dropdownOpenDot, setDropdownOpendot] = useState(false);
  const toggledot = () => setDropdownOpendot((prevState) => !prevState);
  const exportPDF = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);
    const title = "DataTables | Company Bench";

    // Extract headers from headerData
    const headers = Object.keys(headerData);
    const data = tableDatas.map((elt) => headers.map((key) => elt[key]));

    

    const content = {
      startY: 50,
      head: [headers], // Use the provided headers
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("DataTables_Companybench.pdf");
  };
  const handlePrev = function () {
    onPageChange(currentPage - 10);
  };

  const handleNext = function () {
    onPageChange(currentPage + 10);
  };

  const tableInstance = useMemo(() => {
    return {
      ...getTableProps(),
      ...getTableBodyProps(),
      headerGroups,
      rows,
      prepareRow,
      selectedFlatRows,
    };
  }, [
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
  ]);
  // function handleSelectGroup(selectedGroup) {
  //   setselectedGroup(selectedGroup);
  // }

  // function handleSelectOptions(selectedOptions) {
  //   setselectedOptions(selectedOptions);
  // }

  return (
    <>
      <div className="">
        <div className="row">
          {/* <div className="d-flex">
            <Col lg={4} md={6}>
              <div className="mb-2 me-2">
                <Input placeholder="Global filter..." />
              </div>
            </Col>
          
          </div> */}
          <Col className=" mt-2 col-12 col-sm-12 col-md-12 col-lg-8">
            {/* <Row className="mt-4">
           
                   
                  </Row> */}
            <Row>
              <Col className="col-12">
                <div className="input-group mb-2">
                  <div className="search-box">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="filter..."
                      value={globalFilter || ""}
                      onChange={(e) => setGlobalFilter(e.target.value)}
                    />
                    <i
                      className="fa fa-search fa-lg"
                      style={{ color: "#0000FF" }}
                    ></i>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col className="col-12 col-sm-12 col-md-12 col-lg-4">
            <Row>
              <Col
                xs={12}
                lg={12}
                sm={12}
                md={12}
                className="text-end d-flex justify-content-end"
              >
                {/* <div>
                  <Link to={"/addcandidate"} className="text-white">
                    <Button color="primary" variant="primary" className=" my-2">
                      <strong>
                        <FiUserPlus />
                      </strong>{" "}
                      Add candidates
                    </Button>
                  </Link>
                </div> */}
                <div>
                  <Dropdown
                    outline
                    color="info"
                    className="mx-2 my-2"
                    isOpen={dropdownOpen}
                    toggle={toggle}
                    {...props}
                    direction="left"
                  >
                    <DropdownToggle className="bg-primary btn-sm">
                      <FaFilter />{" "}
                    </DropdownToggle>
                    <DropdownMenu {...args}>
                      <div
                        className="exportdata filterhover"
                        style={{
                          borderBottom: "1px solid #ccc",
                          width: "250px", // Add your desired width
                          alignItems: "center",
                          padding: "8px", // Add padding if needed
                          marginTop: "0px",
                          cursor: "pointer",
                        }}
                      >
                        <IndeterminateCheckbox
                          style={{ marginRight: "8px" }}
                          {...getToggleHideAllColumnsProps()}
                        />{" "}
                        <strong> &nbsp;&nbsp;Show All Column</strong>
                      </div>
                      {allColumns.map((column) => (
                        <div
                          key={column.id}
                          className="filterhover"
                          style={{
                            borderBottom: "1px solid #ccc",
                            height: "40px", // Add your desired height
                            width: "100%", // Add your desired width
                            // backgroundColor: "#F2F2F2", // Add your desired background color
                            display: "flex",
                            alignItems: "center",
                            padding: "8px", // Add padding if needed
                            cursor: "pointer",
                          }}
                        >
                          <label
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <input
                              type="checkbox"
                              {...column.getToggleHiddenProps()}
                              style={{ marginRight: "8px", cursor: "pointer" }}
                            />{" "}
                            <span style={{ cursor: "pointer" }}>
                              {column.id}
                            </span>
                          </label>
                        </div>
                      ))}
                      <br />
                    </DropdownMenu>
                  </Dropdown>
                </div>
                <div>
                  {/* <Dropdown
                    outline
                    color="info"
                    className="my-2"
                    isOpen={dropdownOpenDot}
                    toggle={toggledot}
                    {...props}
                    direction="down"
                  >
                    <DropdownToggle className="bg-primary btn-sm">
                      <FiMoreHorizontal />
                    </DropdownToggle>
                    <DropdownMenu className="border mt-1">
                      <DropdownItem className="filterhover border-bottom">
                        <CSVLink data={data} filename="DataTables companybench">
                          <strong className="text-primary">
                            <PiMicrosoftExcelLogoLight />
                            &nbsp;Export Excel
                          </strong>
                        </CSVLink>
                      </DropdownItem>
                      <DropdownItem>
                        <Link onClick={exportPDF}>
                          <strong className="text-primary">
                            <VscFilePdf />
                            &nbsp;Export Pdf
                          </strong>
                        </Link>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown> */}
                </div>
              </Col>
            </Row>
          </Col>
        </div>
      </div>
      <div className="">
        <Row>
          <div className="table-responsive ">
            <Col xl={12} lg={12} md={12} sm={12}>
              <table
                {...tableInstance}
                className="table table-bordered  table-striped"
              >
                <thead className="thead-custom-color">
                  {headerGroups.map((headerGroup) => (
                    <tr
                      key={headerGroup.id}
                      {...headerGroup.getHeaderGroupProps()}
                      className="table"
                    >
                      {headerGroup.headers.map((column) => (
                        <th
                          key={column.id}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps(),
                          )}
                          style={{ color: "#FFFFFF" , width:'50px' }}
                          className={
                            column.isSorted
                              ? column.isSortedDesc
                                ? "desc"
                                : "asc"
                              : ""
                          }
                        >
                          {column.render("Header")}
                          {/* Render the columns filter UI */}
                          <span className="float-end">
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <i className="fas fa-sort-up bg-primary text-light p-0 "></i> // Add your descending icon class
                              ) : (
                                <i className="fas fa-sort-down bg-primary p-0 text-light"></i> // Add your ascending icon class
                              )
                            ) : (
                              <i className="fas fa-sort bg-primary p-0 text-light"></i> // Add your default icon class
                            )}
                          </span>
                          {/* <div>
                      {" "}
                      {column.id !== "action" && column.canFilter
                        ? column.render("Filter")
                        : null}
                    </div> */}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {page.map((row, rowIndex) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} key={rowIndex}>
                        {row.cells.map((cell, cellIndex) => (
                          <td
                            {...cell.getCellProps()}
                            key={cellIndex}
                            style={{
                              maxWidth: "100px", // Set your desired fixed width
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {cell.render("Cell")}
                            {/* {cell.column.id === 'email' ? <FaCoffee /> : cell.render('Cell')} */}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
                {/* <pre>
            <code>
              {JSON.stringify(
                {
                  selectedFlatRows: selectedFlatRows.map((row) => row.original),
                },
                null,
                2
              )}
            </code>
          </pre> */}
              </table>
            </Col>
          </div>
          <div className="d-flex justify-content-end">
            {/* Pagination */}
            <div className="w-100 d-flex flex-row-reverse">
              {/* <button
                className="btn btn-primary btn-sm m-1"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<< Start"}
              </button> */}


              {/* <span>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </span> */}

          
          {/* data.length > 10 &&  */}

          <button
            className="btn btn-primary btn-sm m-1"
           // onClick={() => nextPage()}
            onClick={handleNext}
            //disabled={!canNextPage}
            //disabled={endIndex >= totalCount}
            //disabled={currentPage === 0}
            disabled={endIndex >= totalCount}
          >
            {"Next >"}
          </button>
          
             
             {/* data.length >10 &&  */}
             <button
                className="btn btn-primary btn-sm m-1"
                //onClick={() => previousPage()}
                onClick={handlePrev}
                disabled={currentPage === 0}
                //disabled={!canPreviousPage}
                
              >
                {"< Prev"}
              </button>
              
              {/* <button
                className="btn btn-primary btn-sm m-1"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {"End >>"}
              </button> */}
              {/* <span class="ms-2">
                Go to page{" "}
                <strong>
                  <input
                    className="p-1"
                    type="number"
                    defaultValue={pageIndex + 1}
                    onChange={(e) => {
                      const pageNumber = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      gotoPage(pageNumber);
                    }}
                    style={{ width: "50px" }}
                  ></input>
                </strong>{" "}
              </span> */}
              {/* <select
                className="p-2"
                value={pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
              >
                {[5, 10, 15].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select> */}
            </div>
          </div>
        </Row>
      </div>
    </>
  );
};

export default CustomTable;
