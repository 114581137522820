import moment from "moment/moment";
import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const ApexLineColumn = ({ storePerDayAssmntStatus }) => {

  const [assmntDates , setAssmntsDates] = useState([]);
  const [assmntsCompleted , setAssmntsCompleted] = useState([]);
  const [assmntsInitiated , setAssmntsInitiated] = useState([]);
  const [totalCandidates , setTotalCandidates] = useState([]);

  useEffect(() => {
    
    const first10Records = storePerDayAssmntStatus.slice(0, 11);
    const dates = first10Records.map((assmnt) => moment(assmnt.date).format("DD MMM") );
    const completed = first10Records.map((assmnt) => assmnt.completedCount);
    const initiated = first10Records.map((assmnt) => assmnt.initiatedCount);
    const totals = first10Records.map((assmnt) => assmnt.totalCandidates);
    
    setAssmntsDates(dates);
    setAssmntsCompleted(completed);
    setAssmntsInitiated(initiated);
    setTotalCandidates(totals);
  }, [storePerDayAssmntStatus]);
  
  const series = [
    {
      name: "Candidates",
      data: totalCandidates,
    },
    {
      name: " Assessments Initiated",
      data: assmntsInitiated,
    },
    {
      name: "Assessments Completed",
      data: assmntsCompleted,
    },
   
    
   
  ];
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "60%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },

    colors: ["#28419E","#B6E7FA", "#01ABED" ],
    xaxis: {
      labels: {
        rotate: -45,
      },
      categories: assmntDates,
      
      
    },
    yaxis: {
      title: {
        text: "Assessment Counts",
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  };

  return (
    <>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={369}
        className="apex-charts"
      />
    </>
  );
};

export default ApexLineColumn;
