import React, { useState,useEffect } from "react";
import { Button, Card, CardBody, Col, PopoverBody, PopoverHeader, Row ,Tooltip, UncontrolledPopover} from "reactstrap";
import CountUp from "react-countup";
import RadialChart from "../../common/charts/RadialChart";
import { Link } from "react-router-dom";
import { useSSR } from "react-i18next";
import DashboardHelper from './dashboardHelper'
import { n_formatter } from "../../helpers/utils";
import { color } from "echarts";


const DashboardCards = ({ storeDateRange }) => {
  const [showHelper , setShowHelper] = useState(false);
  const [ttop0, setttop0] = useState(false);
  const [ttop1, setttop1] = useState(false);
  const [ttop2, setttop2] = useState(false);
  const [ttop3, setttop3] = useState(false);
  // const [popoverdismiss, setpopoverdismiss] = useState(false);

  const on_click_show_more=()=>{
      setShowHelper(!showHelper)
  }
  return (
    <React.Fragment>
       <Row>
      {/* {(WidgetsData || []).map((widget, key) => ( */}
      {/* <Col xl={2} md={6}> */}
      <div style={{width:'20%'}}>
        <Card className="card-h-100">
          <CardBody className="bg-light-subtle">
            <Row className="align-items-center">
              <Col xs={6}>
                <span className="text-muted mb-3 lh-1 d-block">
                  {"Candidates"}
                </span>
                <h4 className="mb-3">
                  {/* {widget.isDoller === true ? "" : ""} */}
                  <span className="counter-value">
                    <CountUp
                      start={0}
                      end={
                        storeDateRange &&
                        storeDateRange.candidates
                          .candidate_with_selected_date_range
                      }
                      duration={2}
                      // decimals={2}
                      separator=""
                    />
                    {}
                  </span>
                </h4>
              </Col>
              {/* <Col xs={6}>
                <ReactApexChart
                  options={options}
                  series={[{ data: [6,4,7,4,7,8,56,4,6,7] }]}
                  type="line"
                  className="apex-charts mb-2"
                  dir="ltr"
                  height={50}
                />
              </Col> */}
              <div className="text-nowrap">
                <span className={`badge bg-${storeDateRange && storeDateRange.candidates.percentage <= 0 ? "danger" : "success"}-subtle text-muted`}>{storeDateRange && n_formatter(storeDateRange.candidates.percentage)}%</span>
               
                <button
                      id="TooltipRight0"
                      style={{border:'none' , background:'none', padding:'2px'}}
                      className="bx bx-info-circle text-primary"
                      // onClick={() => {
                      //   setpopoverdismiss(!popoverdismiss);
                      // }}
                    >
                      
                    </button>
                    <Tooltip
                       style={{fontSize:"10px"}}
                      isOpen={ttop0}
                      className="bg-secondary font-size-13"
                      target="TooltipRight0"
                      toggle={() => {
                        setttop0(!ttop0);
                      }}
                    >
                      Percentage Change in Candidates Visited since last period
                    </Tooltip>
                <span className="ms-1 text-muted font-size-13">
                Since last date range
                </span>
              
              </div>
            </Row>
          </CardBody>
        </Card>
        </div>
      {/* </Col> */}
      {/* <Col xl={2} md={6}> */}
      <div style={{width:'20%'}}>
        <Card className="card-h-100">
          <CardBody className="bg-light-subtle">
            <Row className="align-items-center">
              <Col xs={6}>
                <span className="text-muted mb-3 lh-1 d-block">
                  {"Assessments Initiated"}
                </span>
                <h4 className="mb-3">
                  {/* {widget.isDoller === true ? "" : ""} */}
                  <span className="counter-value">
                    <CountUp
                      start={0}
                      end={
                        storeDateRange &&
                        storeDateRange.assessments_initiated
                          .initiated_assessments_with_selected_date_range
                      }
                      duration={2}
                      // decimals={2}
                      separator=""
                    />
                    {}
                  </span>
                </h4>
              </Col>
              {/* <Col xs={6}>
                <ReactApexChart
                  options={options}
                  series={[{ data: [6,4,7,4,7,8,56,4,6,7] }]}
                  type="line"
                  className="apex-charts mb-2"
                  dir="ltr"
                  height={50}
                />
              </Col> */}
              <div className="text-nowrap">
                <span className={`badge bg-${storeDateRange && storeDateRange.candidates.percentage <= 0 ? "danger" : "success"}-subtle text-muted`}>{storeDateRange && n_formatter(storeDateRange.assessments_initiated.percentage)}%</span>
                <button
                      id="TooltipRight1"
                      style={{border:'none' , background:'none' ,padding:'2px'}}
                      className="bx bx-info-circle text-primary"
                      // onClick={() => {
                      //   setpopoverdismiss(!popoverdismiss);
                      // }}
                    >
                      
                    </button>
                <span className="ms-1 text-muted font-size-13">
                Since last date range
                </span>
                <Tooltip
                      isOpen={ttop1}
                      style={{fontSize:"10px"}}
                      className="bg-secondary"
                      target="TooltipRight1"
                      toggle={() => {
                        setttop1(!ttop1);
                      }}
                    >
                      Percentage Change in Assessments Initiated since last period
                    </Tooltip>
              </div>
            </Row>
          </CardBody>
        </Card>
        </div>
      {/* </Col> */}
      {/* <Col xl={2} md={6}> */}
      <div style={{width:'20%'}}>
        <Card className="card-h-100">
          <CardBody className="bg-light-subtle">
            <Row className="align-items-center">
              <Col xs={6}>
                <span className="text-muted mb-3 lh-1 d-block">
                  {"Assessments Completed"}
                </span>
                <h4 className="mb-3">
                  {/* {widget.isDoller === true ? "" : ""} */}
                  <span className="counter-value">
                    <CountUp
                      start={0}
                      end={
                        storeDateRange &&
                        storeDateRange.assessments_completed
                          .completed_assessments_with_selected_date_range
                      }
                      duration={2}
                      // decimals={2}
                      separator=""
                    />
                    {}
                  </span>
                </h4>
              </Col>
              {/* <Col xs={6}>
                <ReactApexChart
                  options={options}
                  series={[{ data: [6,4,7,4,7,8,56,4,6,7] }]}
                  type="line"
                  className="apex-charts mb-2"
                  dir="ltr"
                  height={50}
                />
              </Col> */}
              <div className="text-nowrap">
                <span className={`badge text-muted bg-${storeDateRange && storeDateRange.candidates.percentage <= 0 ? "danger-subtle" : "success-subtle"}`}>{storeDateRange && n_formatter(storeDateRange.assessments_completed.percentage)}%</span>
                <button
                      id="TooltipRight2"
                      style={{border:'none' , background:'none' , padding:'2px'}}
                      className="bx bx-info-circle text-primary"
                      // onClick={() => {
                      //   setpopoverdismiss(!popoverdismiss);
                      // }}
                    >
                      
                    </button>
                <span className="ms-1 text-muted font-size-13">
                Since last date range
                </span>
                <Tooltip
                      isOpen={ttop2}
                      style={{fontSize:"10px"}}
                      className="bg-secondary"
                      target="TooltipRight2"
                      toggle={() => {
                        setttop2(!ttop2);
                      }}
                    >
                      Percentage Change in Assessments Completed since last period
                    </Tooltip>
              </div>
            </Row>
          </CardBody>
        </Card>
        </div>
      {/* </Col> */}
      {/* <Col xl={2} md={6}> */}
      <div style={{width:'20%'}}>
        <Card className="card-h-100">
          <CardBody className="bg-light-subtle">
            <Row className="align-items-center">
              <Col xs={6}>
                <span className="text-muted mb-3 lh-1 d-block">
                  {"Certified Candidates"}
                </span>
                <h4 className="mb-3">
                  {/* {widget.isDoller === true ? "" : ""} */}
                  <span className="counter-value">
                    <CountUp
                      start={0}
                      end={
                        storeDateRange &&
                        storeDateRange.certified_candidates
                          .total_certified_candidates
                      }
                      duration={2}
                      // decimals={2}
                      separator=""
                    />
                    {}
                  </span>
                </h4>
              </Col>
              {/* <Col xs={6}>
                <ReactApexChart
                  options={options}
                  series={[{ data: [6,4,7,4,7,8,56,4,6,7] }]}
                  type="line"
                  className="apex-charts mb-2"
                  dir="ltr"
                  height={50}
                />
              </Col> */}
              <div className="text-nowrap">
              
                <span className={`badge bg-${storeDateRange && storeDateRange.candidates.percentage <= 0 ? "danger" : "success"}-subtle text-muted`}>{storeDateRange && n_formatter(storeDateRange.certified_candidates.percentage)}%</span>
                <button
                      id="TooltipRight3"
                      style={{border:'none' , background:'none' , padding:'2px'}}
                      className="bx bx-info-circle text-primary"
                      // onClick={() => {
                      //   setpopoverdismiss(!popoverdismiss);
                      // }}
                    >
                      
                    </button>
                    <Tooltip
                 isOpen={ttop3}
                 style={{fontSize:"10px"}}
                 toggle={() => {
                  setttop3(!ttop3);
                }}
                      // trigger="focus"
                      className="bg-secondary-subtle"
                      target="TooltipRight3"
                      placement="right"
                    >
                      Percentage Change in Candidates Certified since last period
                    </Tooltip>
                <span className="ms-1 text-muted font-size-13">
                Since last date range
                </span>
               
              </div>
            </Row>
          </CardBody>
        </Card>
        </div>
      {/* </Col> */}
      {/* ))} */}
      <Col xl={2} md={6}>
        <RadialChart
          percentage_val={
            storeDateRange && storeDateRange.assessments_pie_chart.percentage
          }
        />
      </Col>
      {/* <Col xl={2} md={6}>
        <div className="mt-5 me-5 vstack">
        <span >Chart shows the percentage of Assessment completed</span>
        <Link
                                to=""
                                onClick={() => {
                                  on_click_show_more();
                                }}
                                className="text-primary"
                              >
                                more details <i className="mdi mdi-arrow-right" />
                              </Link>
        </div>
          
      </Col> */}
    </Row>  
    <DashboardHelper  isCanvasActive={showHelper}  onToggleCanvas={on_click_show_more}/>
    </React.Fragment>
   
   
  );
  
};

export default DashboardCards;
