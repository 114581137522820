import React, { useState, useEffect } from "react";
import { api_get } from "../../services/service";
import { ep } from "../../config/dev";
import {
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Row,
  Col,
  Badge,
} from "reactstrap";

// import classnames from "classnames";
// import { use } from "i18next";

//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
//import JobsTimeLineDetails from "./jobsTimeLineDetails";

const JobsOffCanvas = ({ is_Right, toggleRightCanvas, job_desc }) => {
  // let jobId = job_desc;
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggle1 = (tab) => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab);
    }
  };
  const [activeTab1, setactiveTab1] = useState("5");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const [isFullTextVisible, setIsFullTextVisible] = useState(false);
  const toggleFullTextVisibility = () => {
    setIsFullTextVisible(!isFullTextVisible);
  };
  

  //meta title
  // document.title = "OffCancas | Minia - React Admin & Dashboard Template";

  return (
    <React.Fragment>
      <div>
        <Offcanvas
          style={{ width: "720px", zoom:"80%" }}
          isOpen={is_Right}
          direction="end"
          toggle={toggleRightCanvas}
        >
           <OffcanvasHeader className="border-bottom" toggle={toggleRightCanvas}>
            {/* <h2 className="display-7">Candidate Detail</h2>
            <h6 className="text-success">
              <i className="mdi mdi-check-all me-3 align-middle text-success"></i>
              Assessment Details for the selected Candidate
            </h6> */}
            <h6 className="text-primary">
              Job Details&nbsp;&nbsp;
              <span className="font-size-12 text-muted mt-2">
                ⓘ Job Details displayed, Relevant information for the selected position
              </span>
            </h6>
          </OffcanvasHeader>
          <OffcanvasBody>
            <Row>
              <Col xl={12}>
                <div>
                  {/* To be converted to function component for jobdescription overview */}
                  <div className="d-flex py-3">
                    <div className="flex-grow-1">
                      <h5 className="font-size-14 mb-1">
                        {job_desc.title}{" "} {job_desc.employment_type && '('+job_desc.employment_type+')'}
                        <small className="text-muted float-end">
                          {job_desc.location}
                        </small>
                      </h5>
                      {job_desc.skill_requirements &&
                      job_desc.skill_requirements.length > 0
                        ? job_desc.skill_requirements.map((skill, index) => (
                            <Badge
                              key={index}
                              color=""
                              className="bg-primary-subtle text-primary text-opacity-75 m-1 font-size-11"
                            >
                              {skill}
                            </Badge>
                          ))
                        : "-"}
                      <p className="text-muted">{job_desc.summary}</p>
                      
                      <span>Education :{"  "}  </span>
                      {job_desc.educational_requirements && 
                      <p className="text-muted"> {job_desc.educational_requirements.degree_name +' in ' + job_desc.educational_requirements.field_of_specialisation}</p>}
                       
      
                  
                      <span>Responsibilities : </span>
                      {job_desc.job_responsibilities.map((resp, index)=>
                      <span key={index} className="hstack ">
                              <i className="bx bx-radio-circle align-middle text-muted" />{" "}
                              <p className="mb-0 text-muted">{resp}</p>
                      </span>
                      )}

                      <div>
                        {/* <Link to="#" className="btn btn-primary btn-sm">
                          Apply <i className="mdi mdi-arrow-right ms-1"></i>
                        </Link>
                        &nbsp;
                        <Link to="#" className="btn btn-secondary btn-sm">
                          Download <i className="mdi mdi-arrow-right ms-1"></i>
                        </Link> */}
                      </div>
                    </div>
                  </div>
                  <div className="card-body"></div>
                </div>
              </Col>
            </Row>
          </OffcanvasBody>
        </Offcanvas>
      </div>
    </React.Fragment>
  );
};

export default JobsOffCanvas;
