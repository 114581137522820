import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

// Layout Related Components
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

const Layout = (props) => {
  // const [isPreloader, setIsPreloader] = useState(true);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  //hides right sidebar on body click
  const hideRightbar = (event) => {
    var rightbar = document.getElementById("right-bar");
    //if clicked in inside right bar, then do nothing
    if (rightbar && rightbar.contains(event.target)) {
      return;
    } else {
    }
  };

  // useEffect(() => {
  //   //init body click event fot toggle rightbar
  //   document.body.addEventListener("click", hideRightbar, true);

  //   if (isPreloader === true) {
  //     document.getElementById("preloader").style.display = "block";

  //     setTimeout(function () {
  //       document.getElementById("preloader").style.display = "none";
  //     }, 2500);
  //   } else {
  //     document.getElementById("preloader").style.display = "none";
  //   }
  // }, [isPreloader]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <div className="pace pace-active" id="preloader">
        <div
          className="pace-progress"
          data-progress-text="100%"
          data-progress="99"
          style={{ transform: "translate3d(100%, 0px, 0px)" }}
        >
          <div className="pace-progress-inner"></div>
        </div>
        <div className="pace-activity"></div>
      </div>

      <div id="layout-wrapper" style={{ zoom: "80%" }}>
        <Header />

        <Sidebar />
        <div className="main-content">
          
          {props.children}
          <Footer />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Layout;
