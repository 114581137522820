import { or_get_ist } from "./utils";
import { common } from "../config/dev";

export const log_debug = function (svelte_name, function_name, message) {
  if (common.log_enabled) {
    console.log(
      or_get_ist() +
        " [TIQ-ADMIN][" +
        svelte_name +
        "] " +
        function_name +
        "() " +
        message,
    );
  }
};
