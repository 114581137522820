import React from "react";
import { useState, useEffect } from "react";
//components
import CustomTable from "../../common/CustomTable";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import ApexLineColumn from "../../common/charts/ApexLineColumn";
import BarChart from "../../common/charts/BarChart";
import DashboardCards from "../../components/dashboard/dashboardCards";
//libraries
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { DateRangePicker } from "react-date-range"; // Replace with the actual library you are using
import IconButton from "@mui/material/IconButton";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import classnames from "classnames";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import { useMyContext } from "../../config/MyContext";
//elements and methods
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  CardText,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { ep } from "../../config/dev";
import { api_get } from "../../services/service";
import AssessmentSessionsOffCanvas from "../../components/assessmentsessions/AssessmentSessionsOffCanvas";
import CandidatesOffCanvas from "../../components/candidates/CandidatesOffCanvas";
import Dev from "../../config/dev";

const Dashboard = () => {
  
  //meta title
  document.title =
    "TalentIQ - Revolutionizing Candidate Assessment with AI! 🤖 ";
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [dateRange, setDateRange] = useState("Daterange Picker");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [storeDateRange, setStoreDateRange] = useState("");
  const [storeAssmntRolesData, setStoreAssmntRolesData] = useState("");
  const [allCandidates, setAllCandidates] = useState([]);
  const [assessments, setAssessments] = useState([]);
  const [assmntData, setAssmntData] = useState("");
  const [isAssessmentRightCanvas, setIsAssessmentRightCanvas] = useState(false);
  const [isRightCandidatesCanvas, setIsRightCandidatesCanvas] = useState(false);
  const [candidateProfileDetails, setCandidatesProfileDetails] = useState({});
  const [candidateGuid, setCandidateGuid] = useState("");
  const [isRightAllCandidatesCanvas, setIsRightAllCandidatesCanvas] =
    useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalCounts , setTotalCounts] = useState();
  const [storePerDayAssmntData, setStorePerDayAssmntStatus] = useState([]);
  const [currentPageCand, setCurrentPageCand] = useState(0);
  const [totalCountsCand , setTotalCountsCand] = useState();
  const defaultStartDate = new Date();
  const defaultEndDate = new Date();
  defaultStartDate.setDate(defaultStartDate.getDate() - 45);
  const [selectionRange, setSelectionRange] = useState({
    startDate: defaultStartDate,
    endDate: defaultEndDate,
    key: 'selection',
  });

  useEffect(() => {
    api_get(
      null,
      `${ep.assessment}/v1/assessmentsession/?limit=10&offset=${currentPage}`,
      (_assessments_err, _assessments_res) => {
        if (_assessments_res) {
          const filteredAssessments = _assessments_res.assessmentsessions.filter(assmnts => {
            const createdAtDate = new Date(assmnts.createdAt);
            return createdAtDate >= (selectionRange.startDate) && createdAtDate <= (selectionRange.endDate);
          });
          setAssessments(filteredAssessments);
        }
        else{
          // alert("No Records Found!");
          //toast.warning("No Records Found !", {
            //position: toast.POSITION.TOP_RIGHT,autoClose:2000
          //});
          //setIsPreloader(false);

        }
      },
    );
  }, [currentPage])

  useEffect(() => {
    
    api_get(
      null,
      `${ep.assessment}/v1/candidate/?limit=10&offset=${currentPageCand}`,
      (_candidate_err, _candidate_res) => {
        if (_candidate_res && _candidate_res.candidates.length > 0) {
          let bind_candidates = _candidate_res.candidates;
          const filteredCandidates = bind_candidates.filter(candidate => {
            const createdAtDate = new Date(candidate.createdAt);
            return createdAtDate >= selectionRange.startDate && createdAtDate <= selectionRange.endDate;
          });
          setAllCandidates(filteredCandidates);
        }
        if (_candidate_err) {
        }
      },
    );
  }, [currentPageCand])

  //button handlers and clicks
  const handleButtonClick = () => {
    setShowDatePicker(!showDatePicker);
  };
  const on_click_assmnt_role = (assmntData) => {
    setAssmntData(assmntData);
    setIsAssessmentRightCanvas(!isAssessmentRightCanvas);
  };
  const on_click_candidte_name = (candidate_guid, assmntDetails) => {
    // setCandidate_assmnt_sess_details({});
    setCandidatesProfileDetails(assmntDetails);
    setCandidateGuid(candidate_guid);
    setIsRightCandidatesCanvas(!isRightCandidatesCanvas);
  };
  const on_click_all_candidate_name = (candidate_guid, details) => {
    setCandidateGuid(candidate_guid);
    setIsRightAllCandidatesCanvas(!isRightAllCandidatesCanvas);
  };
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const handleCandPageChange = (newPage) => {
    setCurrentPageCand(newPage);
  };
  const columns = [{
    Header: "Name",
    accessor: "full_name",
    Cell: ({ row }) => (
      <Link
        to="#"
        style={{ cursor: "pointer", textDecoration: "underline" }}
        onClick={() =>
          on_click_candidte_name(row.original.candidate_guid, row.original)
        }
      >
        {row.original.candidates.length > 0
          ? row.original.candidates[0].full_name
          : "-"}
      </Link>
    ),
  },
  {
    Header: "Role",
    accessor: "role",
    Cell: ({ row }) => (
      <Link
        to="#"
        style={{ cursor: "pointer", textDecoration: "underline" }}
        onClick={() => on_click_assmnt_role(row.original)}
      >
        {row.original.role}
      </Link>
    ),
  },
  {
    Header: "Primary Skills",
    accessor: "primary_skills",
    Cell: ({ row }) =>
      row.original.primary_skills.length > 0 ? (
        // <ul style={{ padding: '1rem' }}>
        <>
          {" "}
          {row.original.primary_skills.map((skill, index) => (
            <span key={index} className="bg-success badge me-1 bg-primary">
              {skill.title}
            </span>
          ))}
        </>
      ) : (
        // </ul>
        "-"
      ),
  },

  {
    Header: "Score",
    accessor: "tiq_score",
    Cell: ({ row }) =>
      row.original.score && row.original.status === "completed" ? (
        <div style={{ width: "40px", height: "40px" }}>
        {" "}
        <CircularProgressbarWithChildren
          styles={{
            root: {}, // Adjust these values
            path: {
              stroke: "#5156be",
              strokeLinecap: "round",
              transition: "stroke-dashoffset 0.5s ease 0s",
              transform: "rotate(0.25turn)",
              transformOrigin: "center center",
              pathColor: "#74788d",
            },
            trail: {
              stroke: "#d6d6d6",
              strokeLinecap: "round",
              transform: "rotate(0.25turn)",
              transformOrigin: "center center",
            },
          }}
          value={
            row.original.score.tiq_score.toFixed(0)}
        >
          <div style={{ fontSize: 9 }}>
            <strong>
              {row.original.score.tiq_score.toFixed(0)}
              %
            </strong>
          </div>
        </CircularProgressbarWithChildren>
      </div>
      ) : (
        "-"
      ),
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ row }) => (
      <span
        className={`bg-success badge ${row.original.status === "completed"
          ? "bg-success"
          : row.original.status === "initiated"
            ? "bg-primary"
            : "bg-warning"
          }`}
      >
        {row.original.status}
      </span>
    ),
  },

  {
    Header: "Start Date",
    accessor: "createdAt",
    Cell: ({ row }) => (
      <span>{moment(row.original.createdAt).format("DD MMM,YYYY")}</span>
    ),
  },
  {
    Header: "End Date",
    accessor: "completedAt",
    Cell: ({ row }) => (
      <span>{moment(row.original.completedAt).format("DD MMM,YYYY")}</span>
    ),
  },
  ];
  const allCandidatesColumns = [
    {
      Header: "Name",
      accessor: "full_name",
      Cell: ({ row }) => (
        <Link
          to="#"
          // style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={() =>
            on_click_all_candidate_name(row.original.guid, row.original)
          }
        >
          {row.original.full_name}
        </Link>
      ),
    },
    {
      Header: "Role | Status | Score",
      accessor: "role",
      Cell: ({ row }) =>
        <Row lg={12}>
          <Col className="vstack col-lg-2">
            <div>
              {(row.original.assessmentsessions &&
                row.original.assessmentsessions.length > 0 &&
                row.original.assessmentsessions[0].role) ||
                "-"}
            </div>
            <div>
              {
                (row.original.assessmentsessions &&
                  row.original.assessmentsessions.length > 0 && (
                    <div
                      className={`bg-${row.original.assessmentsessions[0].status === "completed"
                          ? "success text-white"
                          : row.original.assessmentsessions[0].status === "initiated"
                            ? "primary text-white"
                            : "warning text-white"
                        } badge bg-secondary`}
                    >
                      {row.original.assessmentsessions[0].status}
                    </div>
                  )) ||
                "-"
              }
            </div>

          </Col>

          <Col lg={5}>
            {(row.original.assessmentsessions &&
              row.original.assessmentsessions.length > 0 && row.original.assessmentsessions[0].status === "completed" && (
                <div style={{ width: "40px", height: "40px" }} className="ms-5">
                  {" "}
                  <CircularProgressbarWithChildren
                    styles={{
                      root: {}, // Adjust these values
                      path: {
                        stroke: "#5156be",
                        strokeLinecap: "round",
                        transition: "stroke-dashoffset 0.5s ease 0s",
                        transform: "rotate(0.25turn)",
                        transformOrigin: "center center",
                        pathColor: "#74788d",
                      },
                      trail: {
                        stroke: "#d6d6d6",
                        strokeLinecap: "round",
                        transform: "rotate(0.25turn)",
                        transformOrigin: "center center",
                      },
                    }}
                    value={row.original.assessmentsessions &&
                        row.original.assessmentsessions.length > 0 && row.original.assessmentsessions[0].score && row.original.assessmentsessions[0].score.tiq_score.toFixed(0)}>
                    <div style={{ fontSize: 9 }}>
                      <strong>
                        {
                          (row.original.assessmentsessions &&
                            row.original.assessmentsessions.length > 0 && row.original.assessmentsessions[0].score && row.original.assessmentsessions[0].score.tiq_score.toFixed(0))}
                        %
                      </strong>
                    </div>
                  </CircularProgressbarWithChildren>
                </div>
              ))}
          </Col>
        </Row>


    },

    {
      Header: "Email | Contact",
      accessor: "email_id",
      Cell: ({ row }) => (
        <div className="vstack ">
          <div className="font-size-14 text-primary">
            {row.original.email_id}
          </div>
          <div className="font-size-13">{row.original.contact_no}</div>
        </div>
      ),
    },
    // {
    //   Header: "Phone No.",
    //   accessor: "contact_no",
    // },
    // {
    //   Header: "Experience",
    //   accessor: "overall_experience",
    // },
    {
      Header: "Created Date",
      accessor: "createdAt",
      Cell: ({ row }) => moment(row.original.createdAt).format("DD MMM , YYYY"),
    },

    // Add more columns as needed
  ];
  

  
  useEffect(() => {


    const endDate = new Date();
    const startDate = new Date();
    const perStartDate = new Date();
    startDate.setDate(endDate.getDate() - 7);
    perStartDate.setDate(endDate.getDate()-10);
    api_get(
      null,
      `${ep.assessment}/v1/dashbord?from_date=${startDate}&to_date=${endDate}`,

      (dashboard_err, dashboard_res) => {
        document.getElementById("preloader").style.display = "block";

        if (dashboard_res) {
          setStoreDateRange(dashboard_res.dashboard_counts);
          document.getElementById("preloader").style.display = "none";
        }
      },
    );
    
  }, [])



  useEffect(() => {
    api_get(
      null,
      `${ep.assessment}/v1/assessmentsession/?limit=10`,
      (_assessments_err, _assessments_res) => {
        if (_assessments_res) {
          setTotalCounts(_assessments_res.total_data);
          const filteredAssessments = _assessments_res.assessmentsessions.filter(assmnts => {
            const createdAtDate = new Date(assmnts.createdAt);
            return createdAtDate >= (selectionRange.startDate) && createdAtDate <= (selectionRange.endDate);
          });
          setAssessments(filteredAssessments);
        }
      },
    );
    api_get(
      null,
      `${ep.assessment}/v1/candidate/?limit=100`,
      (_candidate_err, _candidate_res) => {
        setTotalCountsCand(_candidate_res.total_data)
        if (_candidate_res && _candidate_res.candidates.length > 0) {
          let bind_candidates = _candidate_res.candidates;
          const filteredCandidates = bind_candidates.filter(candidate => {
            const createdAtDate = new Date(candidate.createdAt);
            return createdAtDate >= selectionRange.startDate && createdAtDate <= selectionRange.endDate;
          });
          setAllCandidates(filteredCandidates);
        }
        if (_candidate_err) {
        }
      },
    );
  }, [selectionRange]);

  useEffect(() => {
    api_get(
      null,
      `${ep.assessment}/v1/dashbord/graph/groupbar`,
      (dashboard_groupbar_err, dashboard_groupbar_res) => {
        document.getElementById("preloader").style.display = "block";

        if (dashboard_groupbar_res) {
          setStoreAssmntRolesData(dashboard_groupbar_res.group_bar);
          document.getElementById("preloader").style.display = "none";
        }
      },
    );
  }, []);
  useEffect(() => {
    api_get(
      null,
      `${ep.assessment}/v1/dashbord/graph/groupbar/assessment/?from_date=${selectionRange.startDate}&to_date=${selectionRange.endDate}`,
      (perDay_assmnt_Status_err, perDay_assmnt_Status_res) => {
        document.getElementById("preloader").style.display = "block";

        if (perDay_assmnt_Status_res) {
          // Extracting relevant information
          const simplifiedData = perDay_assmnt_Status_res.group_bar.map(entry => ({
            date: entry._id,
            totalCandidates: entry.candidateCount,
            initiatedCount: entry.counts.find(count => count.status === 'initiated')?.count?.count || 0,
            completedCount: entry.counts.find(count => count.status === 'completed')?.count?.count || 0,
          }));

          setStorePerDayAssmntStatus(simplifiedData);
          document.getElementById("preloader").style.display = "none";
        }
      },
    );
  }, [selectionRange])
  

  const handleSelect = (ranges) => {
    setSelectionRange(ranges.selection);
    const formattedStartDate = moment(ranges.selection.startDate).format(
      "DD MMM, YYYY",
    );
    const formattedEndDate = moment(ranges.selection.endDate).format(
      "DD MMM, YYYY",
    );

    setDateRange(`${formattedStartDate} - ${formattedEndDate}`);
    //dashboard cards data ////////////////////////////////////////////////////////////////
    api_get(
      null,
      `${ep.assessment}/v1/dashbord?from_date=${ranges.selection.startDate}&to_date=${ranges.selection.endDate}`,
      (dashboard_err, dashboard_res) => {
        document.getElementById("preloader").style.display = "block";

        if (dashboard_res) {
          setStoreDateRange(dashboard_res.dashboard_counts);
          document.getElementById("preloader").style.display = "none";
        }
      },
    );
    //dashboard cards data ended ////////////////////////////////////////////////////////////////
    api_get(
      null,
      `${ep.assessment}/v1/dashbord/graph/groupbar?from_date=${ranges.selection.startDate}&to_date=${ranges.selection.endDate}`,
      (dashboard_groupbar_err, dashboard_groupbar_res) => {
        document.getElementById("preloader").style.display = "block";

        if (dashboard_groupbar_res) {
          setStoreAssmntRolesData(dashboard_groupbar_res.group_bar);
          document.getElementById("preloader").style.display = "none";
        }
      },
    );

    api_get(
      null,
      `${ep.assessment}/v1/dashbord/graph/groupbar/assessment/?from_date=${ranges.selection.startDate}&to_date=${ranges.selection.endDate}`,
      (perDay_assmnt_Status_err, perDay_assmnt_Status_res) => {
        document.getElementById("preloader").style.display = "block";

        if (perDay_assmnt_Status_res) {
          // Extracting relevant information
          const simplifiedData = perDay_assmnt_Status_res.group_bar.map(entry => ({
            date: entry._id,
            totalCandidates: entry.candidateCount,
            initiatedCount: entry.counts.find(count => count.status === 'initiated')?.count?.count || 0,
            completedCount: entry.counts.find(count => count.status === 'completed')?.count?.count || 0,
          }));

          setStorePerDayAssmntStatus(simplifiedData);
          document.getElementById("preloader").style.display = "none";
        }
      },
    );
  };
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const datePickerStyle = {
    position: "absolute",
    right:"0",
    boxShadow: "0px 0px 8px 0px grey",
    //border: "solid 1px black",
    zIndex: "1",
  };



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              {" "}
              <Breadcrumbs title="Dashboard" />
            </Col>
            <Col lg={4}>
              {" "}
              {/* <DaterangePicker /> */}
              <div>
                <form className="app-search d-none d-lg-block">
                  <div className="position-relative">
                    <input
                      type="text"
                      className="form-control text text-center bg-light-subtle"
                      // placeholder={dateRange}
                      value={`${selectionRange.startDate.toLocaleDateString()} - ${selectionRange.endDate.toLocaleDateString()}`}
                      style={{ cursor: "pointer" }}
                      onClick={handleButtonClick}
                      readOnly
                    />
                    {/* <button className="btn btn-primary" type="button"> */}
                    {/* <i className="bx bx-search-alt align-middle"></i> */}
                    <IconButton
                      className="btn"
                      color="primary"
                      onClick={handleButtonClick}
                    >
                      <i className="align-middle">
                        <CalendarMonthIcon />
                      </i>
                    </IconButton>
                    {/* </button> */}
                  </div>
                </form>

                {showDatePicker && (
                  <div style={datePickerStyle}>
                    <DateRangePicker
                      ranges={[selectionRange]}
                      onChange={handleSelect}
                    />

                  </div>
                )}
               
              </div>
            </Col>
          </Row>
          {/* //Assessments Cards Started////////////////////////////////////////////////////////////////////////////////////// */}
          <DashboardCards storeDateRange={storeDateRange} />
          {/* //Card Ended///////////////////////////////////////////////////////////////////////////////////////////////////// */}

          <Row>
            <Col md={6}>
              <Card>
                <CardHeader className="p-3">
                  {/* <h4 className="card-title mb-0">Assessment Analytics </h4> */}
                  <h5 className="text-primary">
              Assessment Analytics&nbsp;&nbsp;
              <span className="font-size-12 text-muted mt-2">
                ⓘ Present chart shows the per day Assessment Analytics
              
              </span>
            </h5>
                </CardHeader>
                <CardBody>
                  <ApexLineColumn storePerDayAssmntStatus={storePerDayAssmntData} />
                </CardBody>
              </Card>
            </Col>

            <Col md={6}>
              <Row>
                <Card>
                <CardHeader className="p-3">
                  {/* <h4 className="card-title mb-0">Assessment Analytics </h4> */}
                  <h5 className="text-primary">
              Role Based Assessments&nbsp;&nbsp;
              <span className="font-size-12 text-muted mt-2">
                ⓘ Present chart shows the most pupular Roles.
              
              </span>
            </h5>
                </CardHeader>
                  <CardBody>
                    <BarChart storeAssmntRolesData={storeAssmntRolesData} />
                  </CardBody>
                </Card>
              </Row>
            </Col>
          </Row>

          <Row>
            <div>
              <Col md={12}>
                <Card>
                  <CardBody>
                    <Nav tabs className="nav-tabs-custom nav-justified-start" style={{ textTransform: "uppercase" }}>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: customActiveTab === "1",
                          })}
                          onClick={() => {
                            toggleCustom("1");
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="fas fa-home"></i>
                          </span>
                          <span className="d-none d-sm-block">Assessments</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: customActiveTab === "2",
                          })}
                          onClick={() => {
                            toggleCustom("2");
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="far fa-user"></i>
                          </span>
                          <span className="d-none d-sm-block">Candidates</span>
                        </NavLink>
                      </NavItem>
                    </Nav>

                    <TabContent
                      activeTab={customActiveTab}
                      className="p-3 text-muted"
                    >
                      <TabPane tabId="1">
                        <Row>
                          
                          <Col sm="12">
                            <CardText className="mb-0">
                              <CustomTable
                               totalCount={totalCounts}
                               currentPage={currentPage}
                               onPageChange={handlePageChange}
                                data={assessments}
                                columns={columns}
                              />
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="2">
                        <Row>
                          <Col sm="12">
                            <CardText className="mb-0">
                              <CustomTable
                                currentPage={currentPageCand}
                                onPageChange={handleCandPageChange}
                                totalCount={totalCountsCand}
                                data={allCandidates}
                                columns={allCandidatesColumns}
                              />
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                  <CardHeader></CardHeader>
                </Card>
              </Col>
            </div>
          </Row>
        </Container>
        {isRightCandidatesCanvas && (
          <CandidatesOffCanvas
            is_Right={isRightCandidatesCanvas}
            candidate_guid={candidateGuid}
            toggleRightCanvas={on_click_candidte_name}
            candidateProfileDetails={candidateProfileDetails}
          />
        )}
        {isRightAllCandidatesCanvas && (
          <CandidatesOffCanvas
            is_Right={isRightAllCandidatesCanvas}
            candidate_guid={candidateGuid}
            toggleRightCanvas={on_click_all_candidate_name}
          // candidateProfileDetails={candidateProfileDetails}
          />
        )}
        {isAssessmentRightCanvas && (
          <AssessmentSessionsOffCanvas
            is_Right={isAssessmentRightCanvas}
            toggleRightCanvas={on_click_assmnt_role}
            assmntGuid={assmntData}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
