import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { Provider } from "react-redux";
// import Login from "./pages/Authentication/login";

// import store from './store'
// import store from "./store";
import store from "./store/store.js";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.Fragment>
    <BrowserRouter>
      <Provider store={store}>
        <App />
        {/* <Login/> */}
      </Provider>
    </BrowserRouter>
  </React.Fragment>,
);
