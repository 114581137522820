import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../pages/dashboard/home";
// import Candidates from "../pages/candidates/Candidates";
import AssessmentSessions from "../pages/assessmentsessions/AssessmentSessions";
import Jobs from "../pages/jobs/Jobs";
import QuestionMaster from "../pages/questioncorpuses/QuestionMaster";
import Companies from "../pages/companies/Companies";
// import MyCandidates from "../pages/mycandidates/MyCandidates";
// import DatatableTables from "../common/DatatableTables";
import CustomTable from "../common/CustomTable";
import CandidatesAll from "../pages/candidates/candidatesAll";

// Authentication related pages
// import Login from "./pages/Authentication/login";
import Login from "../pages/authentication/login";

const userRoutes = [
  //dashboard
  { path: "/dashboard", component: <Dashboard /> },
  // { path:"/candidates",component:<Candidates/>},
  { path: "/assessmentsessions", component: <AssessmentSessions /> },
  { path: "/jobs", component: <Jobs /> },
  { path: "/questioncorpuses", component: <QuestionMaster /> },
  { path: "/companies", component: <Companies /> },
  // {path:"/mycandidates", component:<MyCandidates/>},
  { path: "/datatables", component: <CustomTable /> },
  { path: "/candidates", component: <CandidatesAll /> },

  // // this route should be at the end of all other routes
  { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
];

const authRoutes = [
  //authencation page

  { path: "/login", component: <Login /> },
];

export { userRoutes, authRoutes };
