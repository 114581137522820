import React, { useEffect, useState } from "react";
import { api_get } from "../../services/service";
import { ep } from "../../config/dev";
import { ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Row,
  Col,
  Card,
  Form,
  Spinner,
  CardBody,
  Input,
  Label,
} from "reactstrap";
import { api_post } from "../../services/service";
import { useFormik } from "formik";
import { ToastContainer,toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


//Import Breadcrumb

const InviteNewCandidate = ({ candidate_name, candidate_email }) => {
  //   const [selectedFiles, setselectedFiles] = useState([]);
  const [assessmentSessionRoles, setAssessmentSessionRoles] = useState([]);
  const [store_full_name, setStore_full_name] = useState("");
  const [store_email_id, setStore_email_id] = useState("");
  const [resp_candidate_guid, setResp_candidate_guid] = useState("");
  //   const [isResumeParsed, setIsResumeParsed] = useState(false);
  const [isInviteSent, setIsInviteSent] = useState(false);
  const [isRoleSelected, setIsRoleSelected] = useState(false);
  const [selectedRole, setSelectedRole] = useState(""); // State to hold selected role
  const [storeInvitationLink, setStoreInvitationLink] = useState("");
  const [invitationSending, setInvitationSending] = useState(false);

  const requestBody = {
    role: "",
  };

  useEffect(() => {
    api_get(
      null,
      "https://i8e43d5anj.execute-api.ap-south-1.amazonaws.com/latest/v1/assessmentsessionrole",
      (assmnt_sess_role_error, assmnt_sess_role_res) => {
        if (assmnt_sess_role_res) {
          // setInterval(() => {
          const roles = [
            ...new Set(
              assmnt_sess_role_res.assessmentsessionroles.map(
                (role) => role.title,
              ),
            ),
          ];
          // assmnt_sess_role_res.assessmentsessionroles.map((role) => role.title);
          setAssessmentSessionRoles(roles);

          // }, 1000);
        }
      },
    );
  }, []);

  function handleRoleSelection(event) {
    const selectedValue = event.target.value;
    setSelectedRole(selectedValue);
    setIsRoleSelected(true);
    // setIsRoleSelected(!!selectedValue); // Set isRoleSelected to true if a role is selected
  }
  // function validateEmail(value) {
  //   let error;
  //   if (!value) {
  //     error = 'Required';
  //   } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
  //     error = 'Invalid email address';
  //   } else if (!/.com$/i.test(value)) {
  //     error = 'Email must end with .com';
  //   }
  //   return error;
  // }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      full_name: candidate_name,
      email: candidate_email,
    },
    validationSchema: Yup.object({
      full_name: Yup.string().required("Please Enter Your Full Name").max(30, 'cannot enter more than 30 characters').matches(/^[A-Za-z .]+$/, 'Full Name should only contain letters and spaces')
      ,
      email: Yup.string().required("Please Enter Your Email").email("Invalid email address").max(50, "email must not exceed more than 50 characters")
        .matches(/^[^\s]+@.+\..+$/, 'Invalid email format'),
    }),
    // function handleInviteAssessment(event) {
    onSubmit: (values) => {
      
     
      setInvitationSending(true);

      const candidateData = {
        full_name: values.full_name,
        email_id: values.email,
      };
      const apiEndpoint = ep.assessment + "/v1/candidate";
      const requestBody = {
        candidate: candidateData,
      };
      api_post(null, apiEndpoint, requestBody, (error, responseData) => {
        if (responseData) {
          // setResp_candidate_guid(responseData.candidate.guid);
          sendInvitationLink(responseData.candidate.guid);
           toast("Invitation Sent Successfully !", {
        position: toast.POSITION.TOP_RIGHT,className:"bg-success text-white"
        }) 
      }
          // createResume();
        
      });
    },
  });

  const sendInvitationLink = (selected_candidate_guid) => {
    requestBody.role = selectedRole;
    api_post(
      null,
      ep.assessment + "/v1/candidate/" + selected_candidate_guid + "/invite/email",
      requestBody,
      (error, responseData) => {
        if (error) {
        } else {
          if (responseData) {
            setInvitationSending(false);
            setIsInviteSent(true);
            const store_invitation_link = responseData.assessment_link;
            setStoreInvitationLink(store_invitation_link);
          }
        }
      },
    );
  };



  return (
    <React.Fragment>
      <ToastContainer/>
      <h5 className="text-primary mb-2">
        {/* <h5 className="text-primary"> */}
        {" "}
        {/* <i className="mdi mdi-check-all me-3 align-middle text-primary"></i> */}
        Assessment Invitation&nbsp;&nbsp;
        <span className="font-size-12 text-muted">
          ⓘ Email with the Assessment link will be sent
        </span>
        {/* </h5> */}
      </h5>
      <Card>

        <CardBody>


          <Form
            // onClick={(e) => handleInviteAssessment(e)}
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row className="mb-4">
              <Label
                htmlFor="full_name"
                className="col-sm-3 col-form-label"
              >
                Full name
              </Label>
              <Col sm={9}>
                <Input
                  type="name"
                  name="full_name"
                  id="full_name"
                  className="form-control"
                  // onChange={(e) => on_change_email_and_name(e, "name")}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.full_name || ""}
                  invalid={
                    validation.touched.full_name && validation.errors.full_name
                      ? true
                      : false
                  }
                  // id="horizontal-firstname-input"
                  placeholder="Enter Your First Name"
                // value={store_full_name}
                />
                {/* <ErrorMessage name="full_name" component="div" /> */}
              </Col>
            </Row>
            <Row className="mb-4">
              <Label
                htmlFor="horizontal-email-input"
                className="col-sm-3 col-form-label"
              >
                Email
              </Label>
              <Col sm={9}>
                <Input
                  type="email"
                  name="email"
                  className="form-control"
                  // validate={validateEmail}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ""}
                  invalid={
                    validation.touched.email && validation.errors.email
                      ? true
                      : false
                  }
                  id="horizontal-email-input"
                  // onChange={(e) => on_change_email_and_name(e, "email")}
                  // value={store_email_id}
                  placeholder="Enter Your Email"
                />

              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                {" "}
                <Label
                  htmlFor="horizontal-password-input"
                  className="col-form-label"
                >
                  Select Role
                </Label>
              </Col>
              <Col lg={9}>
                <select
                  className="form-select"
                  onChange={handleRoleSelection} // Call the handleRoleSelection function on change
                  value={selectedRole}
                // style={{ width: "71%", marginLeft: "11px" }}
                >
                  <option className="form-control">Select</option>
                  {assessmentSessionRoles.map((role) => (
                    <option key={role}>{role}</option>
                  ))}
                </select>

               
              </Col>
            </Row>

            <Row className="justify-content-end">
              <Col sm={9}>
                <div>
                  {/* <button type="submit" className="btn btn-primary w-md">
                    Submit
                  </button> */}
                  {/* {isRoleSelected && ( */}
                  <button className="btn btn-primary" type="submit">
                    <i className=" fas fa-envelope me-2"></i> Invite for
                    Assessment
                  </button>
                  {/* )} */}
                </div>
              </Col>
            </Row>
          </Form>
          <div className="mt-2"></div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default InviteNewCandidate;
