// ToastComponent.js
import React from "react";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ToastComponent = ({ position, message, type, autoClose }) => {
  const notify = () => {
    toast(message, {
      position: position || "top-right",
      hideProgressBar: false,
      className: `bg-${type} text-white`,
      transition: Slide,
      autoClose: autoClose || 3000, // Default auto-close duration: 3 seconds
    });
  };
  // Call notify immediately when the component mounts
  React.useEffect(() => {
    notify();
  }, []); // Empty dependency array ensures this effect runs once when the component mounts

  return <ToastContainer />;
};

export default ToastComponent;
