
import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Modal,
} from "reactstrap";
import classnames from "classnames";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";

const CodeSnippetModal = ({ isModalActive, onToggleModal, codeSnippet }) => {
  const tog_center = (e) => {
    onToggleModal(!isModalActive);
  };
  const [customActiveTab, setcustomActiveTab] = useState("1");

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  return (
    <React.Fragment>
      <Col lg={6}>
        <Card>
          <CardBody>
            <div>
              <Modal
                isOpen={isModalActive}
                toggle={() => {
                  tog_center();
                }}
                centered={true}
                className="modal-xl"
                style={{ zoom: "80%" }}
              >
                <div className="px-4 py-4">
                  <h5 className="text-primary">
                    Coding Assessment Details&nbsp;&nbsp;
                    <span className="font-size-12 text-muted mt-2">
                      ⓘ Present comprehensive details Coding Test.
                    </span>
                  </h5>

                  <Nav
                    tabs
                    className="mt-4 nav-tabs-custom nav-justified-start"
                  >
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "1" ,  
                        })}
                        onClick={() => {
                          toggleCustom("1");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-user"></i>
                        </span>
                        <span className="d-none d-sm-block">Use Case</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "2" , 
                        })}
                        onClick={() => {
                          toggleCustom("2");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-home"></i>
                        </span>
                        <span className="d-none d-sm-block">Evaluation</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "3",  "bg-light-subtle": customActiveTab === "3",
                        })}
                        onClick={() => {
                          toggleCustom("3");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-home"></i>
                        </span>
                        <span className="d-none d-sm-block">Code Snippet</span>
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={customActiveTab} className="p-2">
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                          <div className="">
                            <div className="card-body">
                              {/* Summary and Score */}
                              <div className="row">
                                <div className="col-sm order-2 order-sm-1">
                                  <div className="d-flex align-items-start mt-3 mt-sm-0">
                                    <div className="flex-grow-1">
                                      <div>
                                        <p className="font-size-14">
                                          {codeSnippet && codeSnippet.code_result &&
                                            codeSnippet.code_result.summary}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Hints */}
                              <div className="row mt-2">
                                <div className="col-sm order-2 order-sm-1">
                                  <div className="d-flex align-items-start mt-3 mt-sm-0">
                                    <div className="flex-grow-1">
                                      <div>
                                        <h5 className="font-size-16 mb-1">
                                          Hints
                                        </h5>
                                        {codeSnippet.hints &&
                                          codeSnippet.hints.map(
                                            (hint, index) => (
                                              <div className="d-flex flex-wrap align-items-start gap-2 gap-lg-3 text-muted font-size-13">
                                                <div>
                                                  <i className="mdi mdi-circle-medium me-1 text-success align-middle" />
                                                  {hint}
                                                </div>
                                              </div>
                                            ),
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Recommendation */}
                              <div className="row mt-4">
                                <div className="col-sm order-2 order-sm-1">
                                  <div className="d-flex align-items-start mt-3 mt-sm-0">
                                    <div className="flex-grow-1">
                                      <div>
                                        <h5 className="font-size-16 mb-1">
                                          Recommendations
                                        </h5>
                                        {codeSnippet.hints &&
                                          codeSnippet.recommendations.map(
                                            (recommendation, index) => (
                                              <div className="d-flex flex-wrap align-items-start gap-2 gap-lg-3 text-muted font-size-13">
                                                <div>
                                                  <i className="mdi mdi-circle-medium me-1 text-success align-middle" />
                                                  {recommendation}
                                                </div>
                                              </div>
                                            ),
                                          )}

                                      
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* end card body */}
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col sm="12">
                          <div className="">
                            <div className="card-body">
                              {/* Summary and Score */}
                              <div className="row">
                                <div className="col-sm order-2 order-sm-1">
                                  <div className="d-flex align-items-start mt-3 mt-sm-0">
                                    <div className="flex-grow-1">
                                      <div>
                                        <p className="font-size-14">
                                          {codeSnippet && codeSnippet.code_result &&
                                            codeSnippet.code_result.summary}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-auto order-1 order-sm-2">
                                  <div className="d-flex align-items-start justify-content-end gap-2">
                                    <div
                                      className=""
                                      style={{ width: "60px", height: "50px" }}
                                    >
                                      <CircularProgressbarWithChildren
                                        styles={{
                                          root: {}, // Adjust these values
                                          path: {
                                            stroke: "#5156be",
                                            strokeLinecap: "round",
                                            transition:
                                              "stroke-dashoffset 0.5s ease 0s",
                                            transform: "rotate(0.25turn)",
                                            transformOrigin: "center center",
                                            pathColor: "#74788d",
                                          },
                                          trail: {
                                            stroke: "#d6d6d6",
                                            strokeLinecap: "round",
                                            transform: "rotate(0.25turn)",
                                            transformOrigin: "center center",
                                          },
                                        }}
                                        value={
                                          codeSnippet &&
                                          (
                                            (codeSnippet.score /
                                              codeSnippet.max_score) *
                                            100
                                          ).toFixed(0)
                                        }
                                      >
                                        <div style={{ fontSize: 12 }}>
                                          <strong>
                                            {codeSnippet &&
                                              (
                                                (codeSnippet.score /
                                                  codeSnippet.max_score) *
                                                100
                                              ).toFixed(0)}
                                            %
                                          </strong>
                                        </div>
                                      </CircularProgressbarWithChildren>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Reasoning */}
                              <div className="row mt-2">
                                <div className="col-sm order-2 order-sm-1">
                                  <div className="d-flex align-items-start mt-3 mt-sm-0">
                                    <div className="flex-grow-1">
                                      <div>
                                        <h5 className="font-size-16 mb-1">
                                          Reasoning
                                        </h5>
                                        {codeSnippet && codeSnippet.code_result &&
                                          codeSnippet.code_result.reasoning.map(
                                            (code, index) => (
                                              <div className="d-flex flex-wrap align-items-start gap-2 gap-lg-3 text-muted font-size-13">
                                                <div>
                                                  <i className="mdi mdi-circle-medium me-1 text-success align-middle" />
                                                  {code}
                                                </div>
                                              </div>
                                            ),
                                          )}

                                        {/* <div className="d-flex flex-wrap align-items-start gap-2 gap-lg-3 text-muted font-size-13">
                                              <div><i className="mdi mdi-circle-medium me-1 text-success align-middle" />aenean euismod elementum nisi quis eleifend quam adipiscing vitae proin sagittis nisl rhoncus mattis rhoncus urna neque viverra justo nec</div>
                                            </div> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Evaluation */}
                              <div className="row mt-4">
                                <div className="col-sm order-2 order-sm-1">
                                  <div className="d-flex align-items-start mt-3 mt-sm-0">
                                    <div className="flex-grow-1">
                                      <div>
                                        <h5 className="font-size-16 mb-1">
                                          Evaluation
                                        </h5>
                                        {codeSnippet && codeSnippet.code_result &&
                                          codeSnippet.code_result.evaluation.map(
                                            (code, index) => (
                                              <div className="d-flex flex-wrap align-items-start gap-2 gap-lg-3 text-muted font-size-13">
                                                <div>
                                                  <i className="mdi mdi-circle-medium me-1 text-success align-middle" />
                                                  {code}
                                                </div>
                                              </div>
                                            ),
                                          )}
                                        {/* <div className="d-flex flex-wrap align-items-start gap-2 gap-lg-3 text-muted font-size-13">
                                              <div><i className="mdi mdi-circle-medium me-1 text-success align-middle" />aenean euismod elementum nisi quis eleifend quam adipiscing vitae proin sagittis nisl rhoncus mattis rhoncus urna neque viverra justo nec</div>
                                            </div> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* end card body */}
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="3">
                      <Row>
                        <Col sm="12">
                          {/* <CardText className="mb-0"> */}
                          {/* <div className="modal-header">
                  <h5 className="modal-title mt-0">Code Snippet</h5>
                </div> */}
                          {/* <div className="modal-body"> */}
                          <pre className="bg-light p-4">
                            <code className="">{codeSnippet.code_snippet}</code>
                          </pre>

                          {/* </div> */}
                          {/* </CardText> */}
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                  {/* </CardBody>
                  </Card> */}
                </div>
              </Modal>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default CodeSnippetModal;
