import React, { useEffect, useState } from "react";
//common components imports
import { ep } from "../../config/dev";
import { common } from "../../config/dev";
import { api_get, api_post } from "../../services/service";
//library imports
import axios from "axios";
import * as XLSX from "xlsx";
import classnames from "classnames";
import Dropzone from "react-dropzone";
import validator from "validator";
import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
// import ToastComponent from "../Common/ToastComponent";
import CustomTable from '../../common/CustomTable'
// import ToastComponent from './../Common/ToastComponent';
import { ToastContainer,toast } from "react-toastify";
import {
  Row,
  Col,
  Card,
  Modal,
  Container,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  Form,
  TabContent,
  TabPane,
  Input,
  Label,
  Button,
  Table,
  Alert,
  UncontrolledAlert
} from "reactstrap";

//Import Breadcrumb
// import Breadcrumbs from "../Common/Breadcrumb";

const AddNewCandidateOffCanvas = ({ isModalActive, onToggleModal }) => {
const [validationErrors, setValidationErrors] = useState({});

  const [selectedFiles, setselectedFiles] = useState([]);
  const [selectedExcelFile, setselectedExcelFile] = useState([]);
  const [assessmentSessionRoles, setAssessmentSessionRoles] = useState([]);
  const [store_full_name, setStore_full_name] = useState("");
  const [store_email_id, setStore_email_id] = useState("");
  const [resp_candidate_guid, setResp_candidate_guid] = useState("");
  const [isResumeParsed, setIsResumeParsed] = useState(false);
  const [isInviteSent, setIsInviteSent] = useState(false);
const [isInviteDisabled, setIsInviteDisabled] = useState(false);
  const [isRoleSelected, setIsRoleSelected] = useState(false);
  const [selectedRole, setSelectedRole] = useState(""); // State to hold selected role
  const [storeInvitationLink, setStoreInvitationLink] = useState("");
  const [invitationSending, setInvitationSending] = useState(false);
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [createExcelCandidates, setCreateExcelCandidates] = useState([]);
  const [isExcelParsed , SetIsExcelParsed] = useState(false);
  const [SelectedFilesVisibility , setSelectedFilesVisibility ]= useState(true);
  // const [displayInviteAlert , setDisplayInviteAlert] = useState(false);
  const endpoint = ep.resume_parsing + "/v1/resumeparsingrequest/file/parse";
  const requestBody = {
    role: "",
  };
  const EscelParsedColumns =[
    {Header:"Name",accessor:"Full_name"},
    {Header:"Email",accessor:"Email"},
    {Header:"Role",accessor:"Role"},
    {Header:"Invite Sent",accessor:"status" ,Cell:({row})=> row.original.status ===
      "success" ? (
        <i className=" fas fa-check text-success"></i>
      ) : (
        <i className="dripicons-cross"></i>
      )},
  ]

  const resetOnCloseModal =()=>{
    setSelectedFilesVisibility(false);
    setselectedFiles([])
    setIsInviteSent(false);
    // setDisplayInviteAlert(false)
    setIsRoleSelected(false);
  }

  const handleCopyToClipBoard = () => {
    setIsRoleSelected(false);
   
  };
  const send_invite_to_excel_candidates = (event) => {
    createExcelCandidates.forEach((element) => {
      const candidateData = {
        full_name: element.Full_name,
        email_id: element.Email,
        owner_guid:localStorage.getItem('user_guid')
      };
      const requestBody = {
        candidate: candidateData,
      };
      api_post(
        null,
        ep.assessment + "/v1/candidate",
        requestBody,
        (error, responseData) => {
          if (error) {
          } else {
            element.status = "success";

            setResp_candidate_guid(responseData.candidate.guid);
            // createResume();
            sendInvitationLink(responseData.candidate.guid, element.Role);
          }
        },
      );
    });

    // const apiEndpoint = ep.assessment + "/v1/candidate";
  };


  

  const sendInvitationLink = (candidate_guid, selectedRole) => {
    requestBody.role = selectedRole;
    api_post(
      null,
      ep.assessment + "/v1/candidate/" + candidate_guid + "/invite/email",
      requestBody,
      (error, responseData) => {
if (error) {
        } else {
        if (responseData) {
        

          setInvitationSending(false);
          setIsInviteSent(true);
         
          const store_invitation_link = responseData.assessment_link;
          setStoreInvitationLink(store_invitation_link);
        }
        }
      },
    );
  };

  useEffect(() => {
    api_get(
      null,
      "https://i8e43d5anj.execute-api.ap-south-1.amazonaws.com/latest/v1/assessmentsessionrole",
      (assmnt_sess_role_error, assmnt_sess_role_res) => {
        if (assmnt_sess_role_res) {
          // setInterval(() => {
          const roles = [
            ...new Set(
              assmnt_sess_role_res.assessmentsessionroles.map(
                (role) => role.title,
              ),
            ),
          ];
          // assmnt_sess_role_res.assessmentsessionroles.map((role) => role.title);
          setAssessmentSessionRoles(roles);

          // }, 1000);
        }
      },
    );
  }, []);

  function handleRoleSelection(event) {
    const selectedValue = event.target.value;
    setSelectedRole(selectedValue);
    setIsRoleSelected(true);
    // setIsRoleSelected(!!selectedValue); // Set isRoleSelected to true if a role is selected
  }

  function on_change_email_and_name(event, field) {
    const value = event.target.value;
let errors = { ...validationErrors };
    if (field === "name") {
if (!validator.isLength(value, { min: 0, max: 30 }) || !validator.isAlpha(value,['en-IN'],{ignore:' .'})) {
        errors[field] = 'Please Enter Valid Name';
        setValidationErrors(errors);
        setIsInviteDisabled(true);
      }
      else{
          setValidationErrors({});
          setIsInviteDisabled(false);
          // errors[field] = '';
      }
      setStore_full_name(value); // Update state for full name
    } else if (field === "email") {
if (!validator.isLength(value, { min: 0, max: 40 }) || !validator.isEmail(value,{blacklisted_chars:"-$#~'`!%^&*()+={}|/?"})) {
          errors[field] = 'Please Enter Valid Email';
          setValidationErrors(errors);
          setIsInviteDisabled(true);
        }
        else{
            setValidationErrors({});
          setIsInviteDisabled(false);
            // errors[field] = '';
        }
        setStore_email_id(value); // Update state for email
      }
    }
    
    function handleInviteAssessment(event) {
      
      // setDisplayInviteAlert(false);
      setInvitationSending(true);
      const postData = {
        candidate: {
          full_name: store_full_name,
          email_id: store_email_id,
          owner_guid:localStorage.getItem('user_guid')
        },
      };
      api_post(
        null,
        ep.assessment + "/v1/candidate/" + resp_candidate_guid,
        postData,
        (error, responseData) => {
          if (error) {
          } else {
            // requestBody.email=store_emailid;
          }
        },
        );
        //sending assessment link
        requestBody.role = selectedRole;
    api_post(
      null,
      ep.assessment + "/v1/candidate/" + resp_candidate_guid + "/invite/email",
      requestBody,
      (error, responseData) => {
        if (error) {
        } else {
          if (responseData) {
            toast("Invitation Sent Successfully !", {
              position: toast.POSITION.TOP_RIGHT,className:"bg-success text-white"
              
            });
            // setDisplayInviteAlert(true);
            setInvitationSending(false);
            setIsInviteSent(true);
            const store_invitation_link = responseData.assessment_link;
            setStoreInvitationLink(store_invitation_link);
          }
        }
      },
      );
    }
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  function handleExcelAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    );
    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];

          // Parse rows from the worksheet
          const rows = XLSX.utils.sheet_to_json(worksheet, { header: 0 });
          setCreateExcelCandidates(rows);
          SetIsExcelParsed(true);
          // Do something with the parsed rows
        } catch (error) {
        }
      };

      reader.readAsBinaryString(file);
    });
    setselectedExcelFile(files);
  }

  function handleAcceptedFiles(files) {
    setSelectedFilesVisibility(true)
    setIsResumeParsed(true);
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    );
    setselectedFiles(files);
    let selectedFile = files[0];
    const formData = new FormData();
    formData.append("resumes", selectedFile);
    // formData.append("owner_guid", curr_usersession);
    formData.append("source", "tiq-admin");
    axios
      .post(endpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          service_ref: common.service_ref, // Set the content type to multipart/form-data
        },
      })
      .then((response) => {
        setIsResumeParsed(false);
        if (response.data.data) {
          // document.getElementById('hide_label').style.display = 'block';

          setResp_candidate_guid(response.data.data.candidate.guid);
          const parsed_resume_data = response.data.data.resume;
          setStore_full_name(parsed_resume_data.full_name);
          setStore_email_id(parsed_resume_data.email_id);
        }
      });
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const tog_standard = (e) => {
    onToggleModal(!isModalActive);
    resetOnCloseModal();

  };
  //meta title
  // document.title = "Models | Minia - React Admin & Dashboard Template";

  return (
    <React.Fragment>
      <ToastContainer/>
      <div className="page-content">
        <Container fluid={true}>
          <div className="test1">
            <Modal
              className="my-modal"
              isOpen={isModalActive}
              toggle={() => {
                tog_standard();
              }}
            >
              <div className="">
                <button
                  type="button"
                  onClick={tog_standard}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body p-0 m-0" style={{zoom: '95%'}}>
              <div className="px-4 py-4">

                    <h6 className="text-primary">
                    Add Candidate&nbsp;&nbsp;
                    <span className="font-size-12 text-muted mt-2">
                      ⓘ New Assessment will be initiated for the candidate with the assessment link to be sent to the respected candidate. Start by uploading a resume or an Excel file.
                    </span>
                  </h6>
                      <CardBody>
                        <Nav
                          tabs
                          className="nav-tabs-custom nav-justified-start mt-4"
                        >
                         
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: customActiveTab === "1", 
                              })}
                              onClick={() => {
                                toggleCustom("1");
                              }}
                            >
                              <span className="d-block d-sm-none">
                                <i className="fas fa-home"></i>
                              </span>
                              <span className="d-none d-sm-block text-muted font-size-14">
                                Upload Resume
                              </span>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: customActiveTab === "2", 
                              })}
                              onClick={() => {
                                toggleCustom("2");
                              }}
                            >
                                <span className="d-block d-sm-none">
                                  <i className="far fa-user"></i>
                                </span>
                              <span className="d-none d-sm-block text-muted font-size-14">
                                Upload XLS
                              </span>
                            </NavLink>
                          </NavItem>

                  {customActiveTab === "2" ? <div className="hstack"><pre>                                     </pre>
                   <Link to="https://tiq-public-347545.s3.ap-south-1.amazonaws.com/assets/files/SampleCandidates.xlsx" className="btn btn-light btn-sm" >Download Sample Excel</Link></div> : ''}
                        
                        </Nav>

                        <TabContent
                          activeTab={customActiveTab}
                          className=" text-muted"
                        >
                          <TabPane tabId="1">
                            <Row>
                              <Col sm="12">
                                <Col className="col-12">
                                  <Form>
                                    <Dropzone 
                                      onDrop={(acceptedFiles) => {
                                        handleAcceptedFiles(acceptedFiles);
                                      }}
                                    >
                                      {({ getRootProps, getInputProps }) => (
                                        // <div className="dropzone">
                                        <div
                                          className="dz-message needsclick mt-2"
                                          {...getRootProps()}
                                        >
                                          <input {...getInputProps()} />
                                          <div className="">
                                            <i className="display-4 text-muted bx bx-cloud-upload" />
                                          </div>
                                          <h4>Drop/Upload your resume here.</h4>
                                        </div>
                                        // </div>
                                      )}
                                    </Dropzone>
                                    <div
                                      className="dropzone-previews mt-3"
                                      id="file-previews"
                                    >
                                      {SelectedFilesVisibility && selectedFiles.map((f, i) => {
                                        return (
                                          <Card
                                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                            key={i + "-file"}
                                          >
                                            <div className="p-2">
                                              <Row className="align-items-center ms-2">
                                                <Col className="col-auto">
                                                  <div className="d-flex p-2 bg-light-subtle mb-2">
                                                    <i className=" fas fa-file-image mt-1 me-1"></i>
                                                    {f.name}
                                                  </div>
                                                </Col>
                                                <Col>
                                                  {/* <Link
                                                    to="#"
                                                    className="text-muted font-weight-bold"
                                                  >
                                                    {f.name}
                                                  </Link> */}
                                                  <p className="mb-0">
                                                    <strong>
                                                      {f.formattedSize}
                                                    </strong>
                                                  </p>
                                                </Col>
                                                <Col>
                                                  {isResumeParsed && (
                                                    <Spinner
                                                      className="ms-2"
                                                      color="primary"
                                                    />
                                                  )}
                                                </Col>
                                              </Row>
                                            </div>
                                            {!isResumeParsed && (
                                              <div>
                                               
                                                <Row className="mb-3 ms-2">
                                                  <Label
                                                    htmlFor="horizontal-email-input"
                                                    className="col-sm-2 col-form-label"
                                                  >
                                                    Email
                                                  </Label>
                                                  <Col sm={9}>
                                                    <Input
                                                      type="email"
                                                      name="email"
                                                      className="form-control ms-4"
                                                      onChange={(e) =>
                                                        on_change_email_and_name(
                                                          e,
                                                          "email",
                                                        )
                                                      }
                                                      // onBlur={validation.handleBlur}
                                                      value={store_email_id}
                                                      // invalid={
                                                      //   validation.touched.email &&
                                                      //     validation.errors.email
                                                      //     ? true
                                                      //     : false
                                                      // }
                                                      id="horizontal-email-input"
                                                      // onChange={(e) => on_change_email_and_name(e, "email")}
                                                      // value={store_email_id}
                                                      placeholder="Enter Your Email"
                                                    />
{validationErrors.email && <small className="text-danger text-capitalize ms-4">{validationErrors.email}</small>}

                                                  </Col>
                                                </Row>
                                                <Row className="mb-3 ms-2">
                                                  <Label
                                                    htmlFor="horizontal-firstname-input"
                                                    className="col-sm-2 col-form-label"
                                                  >
                                                    Full name
                                                  </Label>
                                                  <Col sm={9}>
                                                    <Input
                                                      type="name"
                                                      name="full_name"
                                                      className="form-control ms-4"
                                                      onChange={(e) =>
                                                        on_change_email_and_name(
                                                          e,
                                                          "name",
                                                        )
                                                      }
                                                      value={store_full_name}
                                                      id="horizontal-firstname-input"
                                                      placeholder="Enter Your Full Name"
                                                      // value={store_full_name}
                                                    />
{validationErrors.name && <small className="text-danger text-capitalize ms-4">{validationErrors.name}</small>}
                                                  </Col>
                                                </Row>
                                              </div>
                                            )}

                                            {!isResumeParsed && (
                                              <Row className="mb-1 ms-2">
                                                <Label
                                                  htmlFor="horizontal-password-input"
                                                  className="col-sm-2 col-form-label"
                                                >
                                                  Select Role
                                                </Label>
                                                <Col sm={9}>
                                               
                                                    <div className="mb-3">
                                                    <select   onChange={
                                                      handleRoleSelection
                                                    } className="form-select ms-4"  value={selectedRole}>
                                                    <option>Select</option>
                                                    {assessmentSessionRoles.map(
                                                      (role) => (
                                                        <option key={role}>
                                                          {role}
                                                        </option>
                                                      ),
                                                    )}
                                                    </select>
                                                    </div>
                                               
                                                </Col>
                                              </Row>
                                            )}
                                          </Card>
                                        );
                                      })}
                                    </div>
                                  </Form>
                                  <div className="mt-2 hstack">
                                    {isRoleSelected && (
                                      <button
                                        className={`btn btn-primary ${isInviteDisabled} ? 'disabled' : '' `}
                                        onClick={(e) =>
                                          handleInviteAssessment(e)
                                        }
disabled={isInviteDisabled}
                                      >
                                        Invite for Assessment
                                        {invitationSending && (
                                          <i className="bx bx-hourglass bx-spin font-size-16 align-middle ms-2"></i>
                                        )}
                                      </button>
                                    )}

                                    {/* {invitationSending && (
                                      <Spinner className="ms-2" color="primary" />
                                    )} */}

                                    {isInviteSent && (
                                      <CopyToClipboard
                                        text={storeInvitationLink}
                                      >
                                        <button
                                          // style={{ marginLeft: "8px" }}
                                          type="button"
                                          className="ms-2 btn btn-secondary buttons-copy buttons-html5"
                                          onClick={(e) =>
                                            handleCopyToClipBoard(e)
                                          }
                                        >
                                          <span>Copy link to clipboard</span>
                                        </button>
                                       
                                      </CopyToClipboard>
                                        )}
                                    {/* {displayInviteAlert && (<UncontrolledAlert className="ms-3 bg-success-subtle " color="success">
                                                Invitataion Sent Successfully!
                                                </UncontrolledAlert>)} */}
                                  
                                    
                                     
                                  

                                  </div>
                                </Col>
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId="2">
                            <Row>
                              <Col sm="12">
                                <Form>
                                {!isExcelParsed && <Dropzone
                                  onDrop={(acceptedFiles) => {
                                    handleExcelAcceptedFiles(acceptedFiles);
                                  }}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    // <div className="dropzone">
                                      <div
                                        className="dz-message needsclick bg-light-subtle"
                                        {...getRootProps()}
                                      >
                                        <input {...getInputProps()} />
                                        <div>
                                          <i className="display-4 text-muted bx bx-cloud-upload" />
                                        </div>
                                        <h4>
                                          Drop/upload your Excel files here.
                                        </h4>
                                      </div>
                                    // </div>
                                  )}
                                </Dropzone>}
                                  <div
                                    className="dropzone-previews mt-3"
                                    id="file-previews"
                                  >
                                    {selectedExcelFile.map((f, i) => {
                                      return (
                                        <Card
                                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                          key={i + "-file"}
                                        >
                                          <div className="p-2">
                                            <Row className="align-items-center">
                                              <Col className="col-auto">
                                                <span><i className="fas fa-file-excel me-1 text-primary"></i>{f.name}</span>
                                                {/* <img
                                                  data-dz-thumbnail=""
                                                  height="80"
                                                  className="avatar-sm rounded bg-light"
                                                  alt={f.name}
                                                  // src={f.preview}
                                                /> */}
                                              </Col>
                                              <Col>
                                                {/* <Link
                                                  to="#"
                                                  className="text-muted font-weight-bold"
                                                >
                                                  {f.name}
                                                </Link> */}
                                                <p className="mb-0">
                                                  <strong>
                                                    {f.formattedSize}
                                                  </strong>
                                                </p>
                                              </Col>
                                            </Row>
                                            {/* //table start////////////////////////////////////////////////////////////////////////////////////////// */}
                                            <div className="">
                                              {/* <table className="table align-middle datatable dt-responsive table-check nowrap">
                                                <thead>
                                                  <tr>
                                                    <th>Candidate Name</th>
                                                    <th>Email</th>
                                                    <th>Role</th>
                                                    <th>Invite Sent</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {createExcelCandidates.map(
                                                    (xls_cands, index) => (
                                                      <tr key={index}>
                                                        <td>
                                                          {xls_cands.Full_name}
                                                        </td>
                                                        <td>
                                                          {xls_cands.Email}
                                                        </td>
                                                        <td>
                                                          {xls_cands.Role}
                                                        </td>
                                                        <td>
                                                          {xls_cands.status ===
                                                          "success" ? (
                                                            <i className=" fas fa-check text-success"></i>
                                                          ) : (
                                                            <i className="dripicons-cross"></i>
                                                          )}
                                                        </td>
                                                      </tr>
                                                    ),
                                                  )}
                                                </tbody>
                                              </table> */}
                                              <CustomTable data={createExcelCandidates} columns={EscelParsedColumns}/>
                                            </div>
                                            {/* table ended////////////////////////////////////////////////////////////////////////////////////////////// */}
                                          </div>
                                        </Card>
                                      );
                                    })}
                                  {isExcelParsed && <Button
                                      onClick={send_invite_to_excel_candidates}
                                    >
                                      Send Invitataions
                                    </Button>}
                                  </div>
                                </Form>
                              </Col>
                            </Row>
                          </TabPane>
                        
                        </TabContent>
                      </CardBody>
                 </div>
              </div>
              {/* <div className="modal-footer"></div> */}
            </Modal>
          </div>
          {/* </CardBody>
              </Card> */}
          {/* </Col>

           
          </Row> */}
        </Container>
      
      </div>
    </React.Fragment>
  );
};

export default AddNewCandidateOffCanvas;
