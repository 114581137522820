import React from "react";
import PropTypes from "prop-types";
import withRouter from "./Common/withRouter";
import { Navigate } from "react-router-dom";
// import { Navigate } from "react-router-dom";

const NonAuthLayout = (props) => {
  
  if (localStorage.getItem("isAuthenticated") === "false" || "" || null || !localStorage.getItem("isAuthenticated")) {
    return <Navigate to={{ pathname: "/login" }} />;
  }
  // return <Navigate to={{ pathname: "/dashboard" }} />;
  
  return <React.Fragment>{props.children}</React.Fragment>;
  
};

NonAuthLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object,
};

export default withRouter(NonAuthLayout);
