import React, { useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { api_post } from "../../services/service";
import { ep } from "../../config/dev";
import {Spinner} from "reactstrap";
function TIQcard({ assmntCardData }) {
const [isCertificateClicked, setIsCertificateClicked] = useState(false);
  // const [isAssmntCompleted, setIsAssmntCompleted] = useState(false);
  const requestBody = {
    certificate: {
      assessment_session_guid: "",
    },
  };

  const handle_download_assmnt_certificate = (event) => {
    setIsCertificateClicked(true);
    event.preventDefault();
    // setIsCertificateDwonloaded(true);
    if (assmntCardData.guid != null || undefined) {
      requestBody.certificate.assessment_session_guid = assmntCardData.guid;
      // let globalIndex=index;
      api_post(
        null,
        ep.document_pages + "/v1/certificate",
        requestBody,
        (error, responseData) => {
          if (responseData) {
            setIsCertificateClicked(false);
            // setIsCertificateDwonloaded(false);
            // window.location.href = responseData.certificate.s3_url;
            window.open(responseData.certificate.s3_url, "_blank");
          } else if(error) {
            setIsCertificateClicked(false)
            // setIsCertificateDwonloaded(false);
          }
        },
      );
    } else {
    }
  };

  // log("status", menuOption);

  return (
    <>
      <div className="col-lg-6 col-md-6 col-sm-12 mb-1 px-2 row mt-3 me-3">
        {/* <div className="candidateCard  rounded p-2"> */}
        <Card className="bg-light p-0 ms-4">
          <CardBody>
            <div className="d-flex flex-wrap align-items-center">
              <h5 className="card-title me-2 text-primary">
                TalentIQ Score&nbsp;
                <span className="font-size-10">
                  <em>ⓘ What is this?</em>
                </span>
                {/* <p className="font-size-11 text-muted">Lorem ipsum dolor sit amet</p> */}
              </h5>
            </div>

            <Row className="align-items-center">
              <div className="col-sm align-self-center">
                <div className="mt-1 mt-sm-0">
                  <p className="mb-1 mt-1 text-uppercase font-size-11">
                    {assmntCardData && assmntCardData.role}
                  </p>
              
                <div className="" style={{ width: "50px", height: "40px" }}>
                    <CircularProgressbarWithChildren
                      styles={{
                        root: {}, // Adjust these values
                        path: {
                          stroke: "#5156be",
                          strokeLinecap: "round",
                          transition: "stroke-dashoffset 0.5s ease 0s",
                          transform: "rotate(0.25turn)",
                          transformOrigin: "center center",
                          pathColor: "#74788d",
                        },
                        trail: {
                          stroke: "#d6d6d6",
                          strokeLinecap: "round",
                          transform: "rotate(0.25turn)",
                          transformOrigin: "center center",
                        },
                      }}
                      value={
                        assmntCardData && assmntCardData.score
                          ? (assmntCardData.score.tiq_score.toFixed(0)): "-" }
                    >
                      <div style={{ fontSize: 11 }}>
                        <strong>
                          {assmntCardData && assmntCardData.score
                            ? (
                                assmntCardData.score.tiq_score.toFixed(0)): "-"}
                          %
                        </strong>
                      </div>
                    </CircularProgressbarWithChildren>
                  </div>

                  {/* <p className="text-muted mb-4">
                    {" "}
                    + 0.0012.23 ( 0.2 % ){" "}
                    <i className="mdi mdi-arrow-up ms-1 text-success"></i>
                  </p> */}

                  <Row className="g-0 mt-4">
                    <Col xs={3}>
                      <div>
                        <p className="mb-1 text-uppercase font-size-11">MCQs</p>
                        <h5 className="font-size-12 text-muted">
                          {assmntCardData && assmntCardData.score
                            ? assmntCardData.score.mcq.score
                            : "-"}
                        </h5>
                      </div>
                    </Col>
                    <Col xs={3}>
                      <div>
                        <p className="mb-1 text-uppercase font-size-11">
                          Coding
                        </p>
                        <h5 className="font-size-12 text-muted">
                          {assmntCardData && assmntCardData.score
                            ? assmntCardData.score.coding.score
                            : "-"}
                        </h5>
                      </div>
                    </Col>
                    <Col xs={3}>
                      <div>
                        <p className="mb-1 text-uppercase font-size-11">
                          Behavioral
                        </p>
                        <h5 className="font-size-12 text-muted">
                          {assmntCardData && assmntCardData.score
                            ? assmntCardData.score.behavioural.score
                            : "-"}
                        </h5>
                      </div>
                    </Col>
                  </Row>

                  <div className=" d-flex mt-2">
                    {/* <Link to="#" onClick={()=>on_click_assmnt_role(assmntCardData)} className="btn btn-secondary btn-sm">
                                View more <i className="mdi mdi-arrow-right ms-1"></i>
                              </Link>&nbsp; */}

                    <Link
                      to="#"
                      onClick={handle_download_assmnt_certificate}
                      className="btn btn-primary btn-sm"
                    >
                      Certificate {isCertificateClicked ? <i className="bx bx-hourglass bx-spin font-size-16 align-middle ms-2"></i> : <i className="mdi mdi-arrow-right ms-1"></i>}
                    </Link>
                    {/* <Link to="#" className="btn btn-sm">
                      Retake <i className="mdi mdi-arrow-right ms-1"></i>
                    </Link> */}
                  </div>
                </div>
              </div>
            </Row>
          </CardBody>
        </Card>
        {/* </div> */}
      </div>
    </>
  );
}

export default TIQcard;
