import React, { useState, useEffect } from "react";
import { api_get } from "../../services/service";
import { ep } from "../../config/dev";
import {
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
  CardText,
  Alert,
} from "reactstrap";
import classnames from "classnames";

const InfoAlert = ({ tabname }) => {
  return (
    <div>
      <Alert color="info" className="alert-label-icon label-arrow mb-0">
        <i className="mdi mdi-alert-circle-outline label-icon"></i>
        {/* <strong>Information Alert</strong> -  */}
        There are <u>no {tabname}</u> to show!
      </Alert>
    </div>
  );
};

const QuestionMasterOffCanvas = ({ is_Right, toggleRightCanvas, qm_guid }) => {
  let jobId = qm_guid;

  const [qm_details, setQm_details] = useState([]);

  useEffect(() => {
    api_get(
      null,
      ep.assessment + "/v1/questioncorpus/" + jobId,
      (_question_corpus_err, _question_corpus_res) => {
        if (_question_corpus_res) {
          if(_question_corpus_res.questioncorpus.type === "coding"){
            setcustomActiveTab("2");
          }
          setQm_details(_question_corpus_res.questioncorpus);
        }
      },
    );
  }, []);

  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  return (
    <React.Fragment>
      <div>
        <Offcanvas
          style={{ width: "700px" , zoom:"80%" }}
          isOpen={is_Right}
          direction="end"
          toggle={toggleRightCanvas}
        >
          {/* <OffcanvasHeader toggle={toggleRightCanvas}>
           
            <h5 className="text-primary">
              
              <span className="font-size-14 text-muted mt-2">
              {qm_details && qm_details.question}
              </span>
            </h5>
          </OffcanvasHeader> */}
           <OffcanvasHeader className="border-bottom" toggle={toggleRightCanvas}>
            {/* <h2 className="display-7">Candidate Detail</h2>
            <h6 className="text-success">
              <i className="mdi mdi-check-all me-3 align-middle text-success"></i>
              Assessment Details for the selected Candidate
            </h6> */}
            <h6 className="text-primary">
              Question Details&nbsp;&nbsp;
              <span className="font-size-12 text-muted mt-2">
                ⓘ Question Details displayed, Relevant information for the selected question
              </span>
            </h6>
          </OffcanvasHeader>
          <OffcanvasBody>
          <h5 className="text-primary mb-3">
              
              <span className="font-size-14 text-muted ">
              {qm_details && qm_details.question}
              </span>
            </h5>
            <Col lg={12}>
              {qm_details && (
                <div>
                  <Nav tabs className="nav-tabs-custom nav-justified-start text-muted font-size-14" style={{textTransform:"uppercase"}} >
                   {qm_details.type === "coding" ? "" : (<NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({active: (qm_details.typen=== "coding" ? (customActiveTab === "2") : (customActiveTab === "1"))})}
                        onClick={() => {
                          toggleCustom("1");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-user"></i>
                        </span>
                        <span className="d-none d-sm-block">Options</span>
                      </NavLink>
                    </NavItem>)}
                   {qm_details.type === "mcq" ? "": (<NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "2",
                        })}
                        onClick={() => {
                          toggleCustom("2");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-user"></i>
                        </span>
                        <span className="d-none d-sm-block">Hints</span>
                      </NavLink>
                    </NavItem>)}
                  {qm_details.type === "mcq" ? " " : ( <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "3",
                        })}
                        onClick={() => {
                          toggleCustom("3");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-user"></i>
                        </span>
                        <span className="d-none d-sm-block">
                          Recommendations
                        </span>
                      </NavLink>
                    </NavItem>)}
                  </Nav>
                  <TabContent
                    activeTab={customActiveTab}
                    className="p-3 text-muted"
                  >
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                            {/* Options :- <br />{" "}
                            <ol> */}
                            {qm_details.options &&
                              (qm_details.options.length > 1 ? (
                                <>
                                {qm_details.options.map((option ,index)=>(
                                  <span className="hstack mb-1" key={index}>
                                      <i className="bx bx-radio-circle align-middle text-muted" />
                                {option}

                                  </span>
                              
                                )

                                )}
                                
                                </>
                              ) : (
                                'No options available'
                              
                              ))}
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                            {/* Hints :- <br />{" "}
                            <ol> */}
                            {qm_details.hints && qm_details.hints.length > 1 ? (
                              <>
                                {/* <p>
                                  Hints :- <br />{" "}
                                </p> */}
                              
                                  {qm_details.hints.map((hint,index) => (
                                    <span className="hstack" key={index}>
                                       <i className="bx bx-radio-circle align-middle text-muted mx-1 mb-0" />
                                    {hint}
                                    </span>
                                 
                                  ))}
                              
                              </>
                            ) : (
                              <InfoAlert tabname="Hints" />
                            )}
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="3">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                            {/* Recommendations :- <br />{" "}
                            <ul> */}
                            {qm_details.recommendations &&
                            qm_details.recommendations.length > 1 ? (
                              <>
                                {/* <p>
                                  Recommendations :- <br />{" "}
                                </p> */}
                               
                                  {qm_details.recommendations.map((rec,index) => (
                                    <span key={index} className="hstack">
                                        <i className="bx bx-radio-circle align-middle text-muted mx-1 mb-0" />
                                        {rec}

                                    </span>
                                  ))}
                              
                              </>
                            ) : (
                              <InfoAlert tabname="Recommendations" />
                            )}
                            {/* </ul> */}
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </div>
              )}
            </Col>
          </OffcanvasBody>
        </Offcanvas>
      </div>
    </React.Fragment>
  );
};

export default QuestionMasterOffCanvas;
