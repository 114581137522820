
import React, { useState } from "react";
import Slider from "react-slick";
import {
  Card,
  CardBody,
  Col,
  Row,
  Modal,
} from "reactstrap";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
const BehaviouralDetailsModal = ({ isModalActive, onToggleModal, videoData }) => {
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const tog_center = (e) => {
    onToggleModal(!isModalActive);
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
  };

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  return (
    <React.Fragment>
      <Col lg={6}>
        <Card>
          <CardBody>
            <div>
              <Modal
                isOpen={isModalActive}
                toggle={() => {
                  tog_center();
                }}
                centered={true}
                className="modal-xl"
                style={{ zoom: "80%" }}
              >
                <div className="px-4 py-4">
                  <h5 className="text-primary">
                    Video Assessment Details&nbsp;&nbsp;
                    <span className="font-size-12 text-muted mt-2">
                      ⓘ Present comprehensive details Behavioural Test.
                    </span>
                  </h5>
               
                  <Row>
                    <Col sm="12">
                      <div className="">
                        <div className="card-body">
                          {/* Summary and Score */}
                          <div className="row">
                            <div className="col-sm order-2 order-sm-1">
                              <div className="d-flex align-items-start mt-3 mt-sm-0">
                                <div className="flex-grow-1">
                                  <div>
                                    <p className="font-size-14">
                                      {/* {videoData.question} */}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-auto order-1 order-sm-2">
                              <div className="d-flex align-items-start justify-content-end gap-2">
                                <div
                                  className=""
                                  style={{ width: "50px", height: "40px" }}
                                >
                                  <CircularProgressbarWithChildren
                                    styles={{
                                      root: {}, // Adjust these values
                                      path: {
                                        stroke: "#5156be",
                                        strokeLinecap: "round",
                                        transition:
                                          "stroke-dashoffset 0.5s ease 0s",
                                        transform: "rotate(0.25turn)",
                                        transformOrigin: "center center",
                                        pathColor: "#74788d",
                                      },
                                      trail: {
                                        stroke: "#d6d6d6",
                                        strokeLinecap: "round",
                                        transform: "rotate(0.25turn)",
                                        transformOrigin: "center center",
                                      },
                                    }}
                                    value={(
                                      (videoData.score / videoData.max_score) *
                                      100
                                    ).toFixed(0)}
                                  >
                                    <div style={{ fontSize: 12 }}>
                                      <strong>
                                        {(
                                          (videoData.score /
                                            videoData.max_score) *
                                          100
                                        ).toFixed(0)}
                                        %
                                      </strong>
                                    </div>
                                  </CircularProgressbarWithChildren>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Reasoning */}
                          <div className="row mt-2">
                            <div className="col-sm-6 order-2 order-sm-1">
                              <div className="d-flex align-items-start mt-3 mt-sm-0">
                                <div className="flex-grow-1">
                                  <video
                                    //   title={`video-${index}`}
                                    className="embed-responsive-item"
                                    controls // Add this line to show video controls (play, pause, etc.)
                                    width="100%" // Set the width to 100% for responsiveness
                                  >
                                    <source
                                      src={
                                        videoData.video_transcript_mp4_s3_url
                                      }
                                      type="video/mp4"
                                    />
                                    Your browser does not support the video tag.
                                  </video>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-5 order-2 order-sm-1">
                              <p className="font-size-14">
                                {videoData && videoData.question}
                              </p>

                              <h5 className="font-size-12 mb-1">Evaluation</h5>
                              <p className="font-size-13">
                                {videoData &&
                                  videoData.video_transcript_result ?
                                  videoData.video_transcript_result.summary : 'No evaluation generated. It seems like the candidate did not speak during the assessment'}
                              </p>
                              <div className="mt-4">
                                <div className="row">
                                
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Evaluation */}
                        </div>
                        {/* end card body */}
                      </div>
                    </Col>
                  </Row>
                  <div>
                    <Slider {...settings}>
                      {videoData &&
                        videoData.video_transcript_result &&
                        videoData.video_transcript_result.evaluation.map(
                          (evals, index) => (
                            <div key={index} className="">
                              <Card className="mt-4 ms-2 bg-light ht-100 rounded border" style={{"height": "150px"}}>
                                <CardBody className="h-0">
                                  <Row lg={12}>
                                    <Col lg={9}>
                                      {" "}
                                      <h4 className="lh-base fw-normal text-black font-size-13">
                                        {evals.criterion}
                                      </h4>
                                      
                                    </Col>
                                    <Col lg={3} className="">
                                      <div
                                        className=""
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                      >
                                        <CircularProgressbarWithChildren
                                          styles={{
                                            root: {}, // Adjust these values
                                            path: {
                                              stroke: "#5156be",
                                              strokeLinecap: "round",
                                              transition: "stroke-dashoffset 0.5s ease 0s",
                                              transform: "rotate(0.25turn)",
                                              transformOrigin: "center center",
                                              pathColor: "#74788d",
                                            },
                                            trail: {
                                              stroke: "#d6d6d6",
                                              strokeLinecap: "round",
                                              transform: "rotate(0.25turn)",
                                              transformOrigin: "center center",
                                            },
                                          }}
                                          value={(
                                            (evals.score / 10) *
                                            100
                                          ).toFixed(0)}
                                        >
                                          <div style={{ fontSize: 9 }}>
                                            <strong>{evals.score}/10</strong>
                                          </div>
                                        </CircularProgressbarWithChildren>
                                      </div>
                                    </Col>
                                    <p className="text-black-50 font-size-12 mt-1 mb-4">{evals.reason}
                                      </p>
                                  </Row>
                                </CardBody>
                              </Card>
                            </div>
                           
                          ),
                        )}
                    </Slider>
                  </div>
                  {/* </CardBody>
                  </Card> */}
                </div>
              </Modal>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default BehaviouralDetailsModal;
