import React, { useState, useEffect } from "react";
import { ep } from "../../config/dev";
import { Link } from "react-router-dom";
import CustomTable from "../../common/CustomTable";
import { api_get } from "../../services/service";
import AssessmentSessionsOffCanvas from "../assessmentsessions/AssessmentSessionsOffCanvas";

import InviteNewCandidate from "../inviteNewCandidate/InviteNewCandidate";

import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap";
import moment from "moment/moment";

import classnames from "classnames";
import TIQcard from "../allCandidates/TIQCard";
import JobsOffCanvas from "../jobs/jobsOffCanvas";

const CandidatesOffCanvas = ({
  is_Right,
  candidate_guid,
  toggleRightCanvas,
  // candidateProfileDetails
}) => {
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [candidate_resume_details, setCandidate_resume_details] = useState([]);
  const [candidate_assmnt_sess_details, setCandidate_assmnt_sess_details] =
    useState([]);
  const [candidates_all_assessments, setCandidates_all_Assessments] = useState(
    [],
  );
  const [isRight, setIsRight] = useState(false);
  const [jobDesc, setJobDesc] = useState("");
  const [roleBasedJobs, setRoleBasedJobs] = useState([]);
  // const [summary, setSummary] = useState("");
  // const [isResumeRightCanvas, setIsResumeRightCanvas] = useState(false);
  const [isAssessmentRightCanvas, setIsAssessmentRightCanvas] = useState(false);
  // const [resumeData, setResumeData] = useState({});
  const [assmntData, setAssmntData] = useState({});
  const [selectedCandidatesDetails, setSelectedCandidatesDetail] = useState("");
  const getInitials = (fullName) => {
    return fullName.split(' ').map((name) => name[0]).join('').toUpperCase();
  };


  const role_based_jobs_columns = [
    { Header: "Company", accessor: "company",Cell: ({ row }) => (
      <Link
        to="#"
        style={{ cursor: "pointer", textDecoration: "underline" }}
        onClick={() => handleNameClick(row.original)}
      >
        {row.original.title}
      </Link>
    ) },
    { Header: "Role", accessor: "title" ,},
    {
      Header: "Date",
      accessor: "createdAt",
      Cell: ({ row }) => moment(row.original.createdAt).format("DD MMM, YYYY"),
    },
    { Header: "Location", accessor: "location" },
  ];

  const assmnt_columns = [
    {
      Header: "Role",
      accessor: "role",
      Cell: ({ row }) => (
        <Link
          to="#"
          onClick={() => {
            on_click_assmnt_role(row.original);
          }}
        >
          {row.original.role}
        </Link>
      ),
    },
    {
      Header: "Date",
      accessor: "createdAt",
      Cell: ({ row }) => moment(row.original.createdAt).format("DD MMM , YYYY"),
    },
    {
      Header: "Score",
      accessor: "tiq_score",
      Cell: ({ row }) =>
        row.original.score ? row.original.score.tiq_score.toFixed(0) : "-",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) =>  
          <div
            className={`bg-${row.original.status === "completed"
                ? "success text-white"
                : row.original.status === "initiated"
                  ? "primary text-white"
                  : "warning text-white"
              } badge bg-secondary`}
          >
            {row.original.status}
          </div>
  
    },
  ];
  // const requestBody = {
  //   certificate: {
  //     assessment_session_guid: "",
  //   },
  // };
  const handleNameClick = (job_desc) => {
    setJobDesc(job_desc);
    setIsRight(!isRight);
  };

  const on_click_assmnt_role = (assmnt_data) => {
    setAssmntData(assmnt_data);
    setIsAssessmentRightCanvas(!isAssessmentRightCanvas);
  };
  useEffect(() => {
    api_get(
      null,
      ep.assessment + "/v1/assessmentsession/candidate/" + candidate_guid,
      (_assmnt_sess_err, _assmnt_sess_res) => {
        if (_assmnt_sess_res) {
          const candidate_role= _assmnt_sess_res.assessmentsessions[0].role;
          //  log_debug("candidatesOffCanvas","getresume","candidate resp-->"+JSON.stringify( _candidate_res));
          setCandidate_assmnt_sess_details(
            _assmnt_sess_res.assessmentsessions[0],
          );
        
          setCandidates_all_Assessments(_assmnt_sess_res.assessmentsessions);
          // api_get(null , ep.entity + "/v1/joblisting/search/" + _assmnt_sess_res.assessmentsessions[0].role , (_job_listing_err, _job_listing_res)=>{
          api_get(
            null,
           ` ${ep.entity}/v1/joblisting/search/${candidate_role}`,
            (_job_listing_err, _job_listing_res) => {
              if (_job_listing_res) {
                setRoleBasedJobs(_job_listing_res.joblisting);
              }
            },
          );
        }
      },
    );
  }, [])
  
 
  useEffect(() => {
   
    api_get(
      null,
      ep.assessment + "/v1/resume/candidate/" + candidate_guid,
      (_candidate_err, _candidate_res) => {
        if (_candidate_res) {
          // setSummary(_candidate_res.resumes[0].summary);
          //  log_debug("candidatesOffCanvas","getresume","candidate resp-->"+JSON.stringify( _candidate_res));
          setCandidate_resume_details(_candidate_res.resumes[0]);
        }
      },
    );
    api_get(
      null,
      ep.assessment + "/v1/candidate/" + candidate_guid,
      (_candidate_err, _candidate_res) => {
        if (_candidate_res) {
          // setSummary(_candidate_res.resumes[0].summary);
          //  log_debug("candidatesOffCanvas","getresume","candidate resp-->"+JSON.stringify( _candidate_res));
          setSelectedCandidatesDetail(_candidate_res.candidate);
        }
      },
    );
  }, []);

  const toggle1 = (tab) => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab);
    }
  };
  const [activeTab1, setactiveTab1] = useState("5");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  return (
    <React.Fragment>
      <div>
        <Offcanvas
          style={{ width: "750px", zoom: "80%" }}
          isOpen={is_Right}
          direction="end"
          toggle={toggleRightCanvas}
        >
          <OffcanvasHeader className="border-bottom" toggle={toggleRightCanvas}>
            {/* <h2 className="display-7">Candidate Detail</h2>
            <h6 className="text-success">
              <i className="mdi mdi-check-all me-3 align-middle text-success"></i>
              Assessment Details for the selected Candidate
            </h6> */}
            <h5 className="text-primary">
              Candidate Details&nbsp;&nbsp;
              <span className="font-size-12 text-muted mt-2">
                ⓘ Present comprehensive details for the specifically selected
                assessment.
              </span>
            </h5>
          </OffcanvasHeader>
          <OffcanvasBody className="p-0">
            {/* <CandidateDetailsComponents candidate_guid={candidate_guid}/> */}
            <Row className="justify-content-between">
              {/* <Col lg={5}> */}

              {/* </Col> */}
              <Col lg={5}>
                {/* <div className="row"> */}

                <div className="row mt-4 ms-4 mb-5">
                  <div className="col-sm order-2 order-sm-1">
                    <div className="d-flex align-items-start mt-4 mt-sm-0">
                      <div className="flex-shrink-0">
                        <div className="avatar-sm me-5">
                          {/* <i className="bx bxs-user bx-md" /> */}
                          <div className="avatar-xm mx-auto mb-0">
                                {selectedCandidatesDetails.full_name != null || '' ? <span className="avatar-title bg-primary text-white font-size-22 display-3 m-0 rounded-circle" style={{ width: "55px", height: "55px" }}>
                                  {getInitials(selectedCandidatesDetails.full_name)}
                                </span>: 
                                    <span className="avatar-title bg-light-subtle text-light display-4 m-0 rounded-circle">
                                    <i className="bx bxs-user-circle"></i> </span>}
                                  </div>

                        </div>
                      </div>
                      <div className="flex-grow-1 ">
                        <div>
                          <h5 className="font-size-16 mb-1">
                            {selectedCandidatesDetails.full_name}
                          </h5>
                          {/* <p className="text-muted font-size-13">{candidate_assmnt_sess_details &&
                            candidate_assmnt_sess_details.role} */}
                          <div className="d-flex flex-wrap gap-1 font-size-16 mb-1">
                            {candidate_resume_details &&
                            candidate_resume_details.programming_languages
                              ? candidate_resume_details.programming_languages
                                  .slice(0)
                                  .map((prim_skill, index) => (
                                    <span
                                      key={index}
                                      
                                      className="badge bg-primary-subtle text-primary font-size-10"
                                    >
                                      {prim_skill}
                                    </span>
                                  ))
                              : "-"}
                          </div>
                          {/* </p> */}
                          <div className="d-flex vstack flex-wrap align-items-start gap-lg-0 text-muted font-size-13">
                            {/* <div><i className="mdi mdi-circle-medium me-1 text-success align-middle" />Development</div> */}
                            {/* <div><i className="mdi mdi-circle-medium me-1 text-success align-middle" />{candidateProfileDetails.candidateEmail}</div> */}
                            <div>
                              <span className="text-truncate">
                                {" "}
                              {selectedCandidatesDetails.email_id && (<> <i className="mdi mdi-email me-1 text-success align-middle" />{" "}
                                {selectedCandidatesDetails.email_id}</>)}
                              </span>
                            </div>
                            <div>
                              <span>
                                {" "}
                               {selectedCandidatesDetails.contact_no && (<><i className="mdi mdi-phone me-1 text-success align-middle" />
                                {selectedCandidatesDetails.contact_no}</>)}
                              </span>
                            </div>
                            <div>
                              <span>
                                {" "}
                               {selectedCandidatesDetails.overall_experience >= 0 && (<><i className="mdi mdi-briefcase me-1 text-success align-middle" />
                                {selectedCandidatesDetails.overall_experience} years</>)}
                              </span>
                             
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-sm-auto order-1 order-sm-2">
                    <div className="mt-2 d-flex mt-2 d-flex flex-row-reverse">
                      <Link to="#" className="btn btn-primary btn-sm">
                        Download Resume{" "}
                        <i className="mdi mdi-arrow-right ms-1"></i>
                      </Link>
                    </div>
                  </div> */}
                </div>
              </Col>
              {/* TIQ CARD DETAILS//////////////////////////////////////////////////////////////////////// */}
             {candidate_assmnt_sess_details && candidate_assmnt_sess_details.score &&
              candidate_assmnt_sess_details.score.tiq_score >0 ? <TIQcard assmntCardData={candidate_assmnt_sess_details} /> :''} 
            </Row>

            <Col lg={12}>
              <Nav
                tabs
                className="nav-tabs-custom nav-justified-start ms-3"
               
              >
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                  
                    className={classnames({
                      active: customActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleCustom("1");
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="fas fa-home"></i>
                    </span>
                    <span className="d-none d-sm-block">Profile</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleCustom("2");
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="far fa-user"></i>
                    </span>
                    <span className="d-none d-sm-block">Assessments</span>
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "3",
                    })}
                    onClick={() => {
                      toggleCustom("3");
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="far fa-user"></i>
                    </span>
                    <span className="d-none d-sm-block">Matching Jobs</span>
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent
                activeTab={customActiveTab}
                className="p-4 text-muted"
              >
                <TabPane tabId="1">
                  <Row className="px-2">
                    <Col xl={12}>
                      <div className="mt-2">
                        <h6 className="font-size-13">Summary :</h6>
                        <span>
                          {candidate_resume_details
                            ? candidate_resume_details.summary
                            : "-"}
                        </span>
                      </div>
                      <div className="mt-4">
                        <h6 className="font-size-13">Skills :</h6>

                        <div className="d-flex flex-wrap gap-2 font-size-16 mt-2">
                          {candidate_resume_details &&
                            candidate_resume_details.programming_languages &&
                            candidate_resume_details.programming_languages
                              .length > 0 ?
                            (candidate_resume_details.programming_languages.map(
                              (skill, skillIndex) => (
                                <span
                                
                                  className="badge bg-primary-subtle text-primary"
                                >
                                  {skill}
                                </span>
                              ),
                            )) : '-'}
                        </div>
                      </div>
                      {/* <div className="table-responsive"> */}
                      {/* <div className="mt-4">
                        <h6 className="font-size-13">Work Experience :</h6>
                        {candidate_resume_details &&
                        candidate_resume_details.work_experiences &&
                        candidate_resume_details.work_experiences.length > 0 ? (
                          <CustomTable
                            data={candidate_resume_details.work_experiences}
                            columns={work_experiences_columns}
                          />
                        ) : (
                          <div className="">
                            <p className="text-muted font-size-12">
                              No Work Experiences Mentioned
                            </p>
                          </div>
                        )}
                      </div> */}
                       <div className="mt-4 mb-4">
                        <h6 className="font-size-13">
                          Work Experience :
                        </h6>
                        <ul class="list-unstyled mb-0">
                          {candidate_resume_details &&
                          candidate_resume_details.work_experiences &&
                          candidate_resume_details.work_experiences
                            .length > 0 ? (
                            candidate_resume_details.work_experiences.map(
                              (work_exp, index) => (
                                <li class="">
                                  <p className="font-size-13 mb-1">
                                    <i class="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                                    <span className="">
                                      {work_exp.company_name}/
                                    </span>
                                    &nbsp;
                                    <em className="">
                                      {work_exp.designation}/
                                    </em>
                                    &nbsp;&nbsp;
                                    <span className="font-size-12"> 
                                      {/* {moment(work_exp.from_date).format(
                                        "DD MM, YYYY",
                                      )} */}
                                     {moment(work_exp.from_date).format("DD MMM, YYYY") +"  -  " + moment(work_exp.to_date).format("DD MMM, YYYY")}
                                    </span>
                                  </p>
                                </li>
                              ),
                            )
                          ) : (
                            <p className="text-muted font-size-12">
                              No Work Experience Mentioned
                            </p>
                          )}
                        </ul>
                      </div>
                      <div className="mt-4 mb-4">
                        <h6 className="font-size-13">
                          Education Qualifications :
                        </h6>
                        <ul class="list-unstyled mb-0">
                          {candidate_resume_details &&
                          candidate_resume_details.educational_qualifications &&
                          candidate_resume_details.educational_qualifications
                            .length > 0 ? (
                            candidate_resume_details.educational_qualifications.map(
                              (education, index) => (
                                <li class="">
                                  <p className="font-size-13 mb-1">
                                    <i class="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                                    <span className="">
                                      {education.college_or_university_name}/
                                    </span>
                                    &nbsp;
                                    <em className="">
                                      {education.degree_name}/
                                    </em>
                                    &nbsp;&nbsp;
                                    <span className="font-size-12">
                                      {moment(education.from_date).format(
                                        "DD MM, YYYY",
                                      )}
                                    </span>
                                  </p>
                                </li>
                              ),
                            )
                          ) : (
                            <p className="text-muted font-size-12">
                              No Education Mentioned
                            </p>
                          )}
                        </ul>
                      </div>
                      {/* </div> */}
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row className="px-2">
                    <Col sm="12 mb-4">
                      {candidates_all_assessments.length > 0 ? (
                        <CustomTable
                          data={candidates_all_assessments}
                          columns={assmnt_columns}
                        />
                      ) : (
                        <div className="">
                          <p className="text-muted font-size-12">
                            No Assessment Has Given Till
                          </p>
                        </div>
                      )}
                    </Col>
                    <Col sm="12">
                      <div className="">
                        {/* <Col sm="8"> */}
                          <InviteNewCandidate
                            candidate_name={selectedCandidatesDetails.full_name}
                            candidate_email={selectedCandidatesDetails.email_id}
                          />
                        {/* </Col> */}
                      </div>
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tabId="3">
                  <Row>
                    <Col sm="12">
                     {roleBasedJobs.length > 0 ? <CustomTable
                        data={roleBasedJobs}
                        columns={role_based_jobs_columns}
                      /> : 'No Jobs Available for the current Role'}
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
              {/* </CardBody>
              </Card> */}
            </Col>
          </OffcanvasBody>
        </Offcanvas>
        {isAssessmentRightCanvas && (
          <AssessmentSessionsOffCanvas
            is_Right={isAssessmentRightCanvas}
            toggleRightCanvas={on_click_assmnt_role}
            assmntGuid={assmntData}
          />
        )}
        {isRight && (
            <JobsOffCanvas
              is_Right={isRight}
              toggleRightCanvas={handleNameClick}
              job_desc={jobDesc}
            />
          )}
      </div>
    </React.Fragment>
  );
};

export default CandidatesOffCanvas;
